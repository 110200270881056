import { useDelete, useFetch } from "../../db/dbHooks.js";
import ListGroup from "react-bootstrap/ListGroup";
import "./CreateOrder.css";
import { ArrowLeftShort, ThreeDotsVertical } from "react-bootstrap-icons";
import { ddmmyy } from "../../fun/fun.js";
import DropDown from "../dropdown/DropDown.js";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader.js";
import { useConfirmation } from "../floating/ConfirmationPopupContext.js";
import { usePopup } from "../floating/PopupContext.js";
import { useEffect } from "react";

export default function OrderView({
  orderID,
  setTrigger,
  clearBg = false,
  refetchMain,
}) {
  const { data, loading, error, refetch } = useFetch(
    `orders/orderwithdetails/${orderID}`
  );

  const preventBubbling = (event) => {
    event.stopPropagation();
  };
  return (
    <div
      className="fixed-bg"
      style={{ backgroundColor: clearBg ? "#00000000" : "" }}
      onClick={setTrigger}
    >
      <div
        className="fixed-inner"
        style={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          fontSize: "13px",
        }}
        onClick={preventBubbling}
      >
        {data ? (
          <>
            <Header
              order={data.order}
              route={data.route}
              setBackClick={setTrigger}
              orderID={orderID}
              refetch={refetchMain}
            />
            <OrderDetails order={data.order} />
            <OrderList orderDetails={data.orderDetails} order={data.order} />
          </>
        ) : (
          loading && <Loader color={"black"} style={{ alignSelf: "center" }} />
        )}
      </div>
    </div>
  );
}

const Header = ({ order, route, setBackClick, orderID, refetch }) => {
  const { showConfirmation } = useConfirmation();
  const { showPopupSuccess, showPopupError } = usePopup();

  const { deleteResponse, deleteLoading, deleteError, deleteData } =
    useDelete("orders");
  const navigate = useNavigate();
  const dropDownOptions = [
    { value: "Edit", label: "Edit" },
    { value: "Delete", label: "Delete" },
  ];

  //handle delete request
  useEffect(() => {
    if (deleteResponse) {
      showPopupSuccess(deleteResponse.message);
      refetch();
    } else if (deleteError) {
      showPopupError(deleteError);
    }
  }, [deleteResponse, deleteError]);

  const handleDropDown = (option) => {
    switch (option.value) {
      case "Edit":
        navigate("/newOrder", {
          state: { createOrder: { orderID, ...route } },
        });
        break;
      case "Delete":
        showConfirmation(
          "Are you sure you want to delete this order",
          () => deleteData(orderID),
          "Delete"
        );

        break;
      default:
        break;
    }
  };
  return (
    <div className="d-flex ">
      {
        <ArrowLeftShort
          size={40}
          style={{ margin: "auto" }}
          onClick={setBackClick}
        />
      }
      <div
        style={{
          width: "80%",
          height: "35px",
          flexGrow: "10",
          fontWeight: 600,
          alignContent: "center",
          textAlign: "center",
          fontSize: "17px",
        }}
      >
        {order.customerName}
      </div>

      <DropDown
        Component={<ThreeDotsVertical size={25} />}
        options={dropDownOptions}
        onSelect={handleDropDown}
      />
    </div>
  );
};

const OrderDetails = ({ order }) => {
  return (
    order && (
      <div className="order-details-header">
        <ListGroup horizontal className="list-header">
          <ListGroup.Item className="w-50">
            {" "}
            <div className="d-flex">
              <p style={{ fontWeight: 600 }}>{"Date: "}</p>
              <p> {ddmmyy(order.orderDate)}</p>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="w-50">
            <div className="d-flex">
              <p style={{ fontWeight: 600 }}>Delivery: </p>
              <p>{ddmmyy(order.deliveryDate)}</p>
            </div>
          </ListGroup.Item>
        </ListGroup>
        <ListGroup horizontal className="list-header">
          <ListGroup.Item className="w-50">
            <div className="d-flex">
              <p style={{ fontWeight: 600 }}>{"By: "}</p>
              <p> {order.username}</p>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="w-50">
            <div className="d-flex">
              <p style={{ fontWeight: 600 }}>{"Status:"}</p>
              <p> {" " + order.status}</p>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </div>
    )
  );
};

const OrderList = ({ orderDetails, order }) => {
  return (
    orderDetails && (
      <>
        <div style={{ height: "70%", overflowY: "auto" }}>
          <ListGroup horizontal className="list-header">
            <ListGroup.Item className="list_larg_field w-50">
              Product
            </ListGroup.Item>
            <ListGroup.Item className="list_small_field w-25">
              Qty
            </ListGroup.Item>
            <ListGroup.Item className="list_small_field w-25">€</ListGroup.Item>
          </ListGroup>
          {orderDetails.map((item) => (
            <ListGroup horizontal key={item.productID}>
              <ListGroup.Item
                className="list_larg_field w-50"
                style={{ textDecoration: item.outOfStock && "line-through" }}
              >
                {item.productName + " - " + Number(item.quantity)}
              </ListGroup.Item>

              <ListGroup.Item className="list_small_field w-25">
                {Number(item.dispatchQTY)}
              </ListGroup.Item>

              <ListGroup.Item className="list_small_field w-25">
                {Number(item.price)}
              </ListGroup.Item>
            </ListGroup>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "10%",
            alignItems: "center",
            marginRight: "15px",
            fontWeight: 600,
          }}
        >
          <ListGroup horizontal className="list-header">
            <ListGroup.Item className="list_larg_field w-50">
              Note: {order.note}
            </ListGroup.Item>
            <ListGroup.Item className="list_small_field w-50">
              Order Total: {Number(order.total)}€
            </ListGroup.Item>
          </ListGroup>
        </div>
      </>
    )
  );
};

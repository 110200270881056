import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
/* import "bootstrap/dist/css/bootstrap.min.css";
 */ import { BrowserRouter } from "react-router-dom";
import { PopupProvider } from "./components/floating/PopupContext";
import { ConfirmationPopupProvider } from "./components/floating/ConfirmationPopupContext";
import { LoadingSpinnerProvider } from "./components/floating/LoadingSpinnerContext";
import { AuthProvider } from "./fun/authContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <PopupProvider>
        <ConfirmationPopupProvider>
          <LoadingSpinnerProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </LoadingSpinnerProvider>
        </ConfirmationPopupProvider>
      </PopupProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// GlobalStateContext.js
import { createContext, useState } from "react";
import { useCookies } from "react-cookie";
import { useLoadingSpinner } from "../components/floating/LoadingSpinnerContext";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const apiLink = process.env.REACT_APP_API_URL;
  const [cookies, setCookie] = useCookies(["token", "sageToken"]);
  const [isAuth, setIsAuth] = useState(false);
  const localDBuser = JSON.parse(localStorage.getItem("NF_DB_USER") || null);
  const [user, setUser] = useState(localDBuser ? { ...localDBuser } : null);
  const { showLoadingSpinner, hideLoadingSpinner, setLoadingSpinnerMessage } =
    useLoadingSpinner();
  const signin = (postResponse) => {
    showLoadingSpinner();
    setLoadingSpinnerMessage("Logging in...");
    localStorage.setItem(
      "NF_DB_USER",
      JSON.stringify({
        userID: postResponse.userID,
        username: postResponse.username,
        userType: postResponse.userType,
        sageLoginState: postResponse.sageLoginState,
        stayLoggedin: postResponse.stayLoggedin,
      })
    );
    setUser(postResponse);
    setIsAuth(true);
    hideLoadingSpinner();
  };

  const signout = async () => {
    showLoadingSpinner();
    setLoadingSpinnerMessage(null, "Logging out");
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "auth/signout",
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error();
      const { message } = await response.json();
      if (message) {
        deleteUserDataAndSignout();
        hideLoadingSpinner();
      }
    } catch (err) {
      setLoadingSpinnerMessage(null, "Error during signout proccess");
      hideLoadingSpinner();
    }
  };

  const deleteUserDataAndSignout = () => {
    localStorage.setItem("NF_DB_USER", null);
    isAuth && setIsAuth(false);
  };

  // Refresh access token
  const setSageSignedinState = (sageLoginState) => {
    const user = {
      ...localDBuser,
      sageLoginState,
    };
    localStorage.setItem("NF_DB_USER", JSON.stringify(user));
    setUser(user);
  };

  // Refresh access token
  const sageSignin = async () => {
    window.location.href = `${process.env.REACT_APP_API_URL}auth/sageAuth?user_id=${user.userID}`;
  };
  // Refresh access token
  const sageSignout = async () => {
    try {
      const authorization = await getAccessToken();
      const response = await fetch(apiLink + `auth/sageRevoke`, {
        credentials: "include",
        headers: {
          authorization,
        },
      });
      if (!response.ok) {
        throw Error;
      } else {
        setSageSignedinState(false);
        window.location.replace("/");
      }
    } catch (err) {}
  };

  // Refresh access token
  const getAccessToken = async () => {
    if (cookies.token && cookies.token !== undefined) {
      return cookies.token;
    } else {
      try {
        const response = await fetch(apiLink + "auth/refreshAccess", {
          credentials: "include",
        });
        if (!response.ok) throw new Error("");

        const { token } = await response.json();

        return token;
      } catch (err) {
        showLoadingSpinner();
        setLoadingSpinnerMessage(null, "Sesstion expired logging out");
        setIsAuth(false);
        hideLoadingSpinner();
        throw err;
      }
    }
  };

  // Refresh access token
  const getSageAccessToken = async () => {
    if (cookies.sageToken && cookies.sageToken !== "undefined") {
      return cookies.sageToken;
    } else {
      try {
        const authorization = await getAccessToken();

        const response = await fetch(apiLink + "auth/refreshSageAccess", {
          credentials: "include",
          headers: {
            authorization,
          },
        });
        if (!response.ok)
          throw new Error("Failed to refresh sage access token");

        const { sageToken } = await response.json();
        return sageToken;
      } catch (err) {
        throw "Login to sage";
      }
    }
  };

  const checkAuthentication = async () => {
    if (user) {
      showLoadingSpinner();
      setLoadingSpinnerMessage(null, "Checking user authentication...");
      try {
        const response = await fetch(apiLink + "auth/checkLoginStatus", {
          credentials: "include",
          headers: { "Content-Type": "application/json" },
        });
        if (!response.ok) throw new Error("response was not ok");
        const { sageLoginState } = await response.json();
        setSageSignedinState(sageLoginState);
        setIsAuth(true);
        hideLoadingSpinner();
      } catch (err) {
        deleteUserDataAndSignout();
        setLoadingSpinnerMessage(null, "Logging out...");
        hideLoadingSpinner();
      }
    }
  };

  const setSageAccessToken = (token) => {
    setCookie("sageToken", token, {
      secure: false, // Only sent over HTTPS
      sameSite: "strict", // Prevents cross-site sending
      maxAge: 240,
      httpOnly: false,
    });
  };

  const setAccessToken = (token) => {
    setCookie("token", token, {
      secure: false, // Only sent over HTTPS
      sameSite: "strict", // Prevents cross-site sending
      maxAge: 840,
      httpOnly: false,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        user,
        signin,
        signout,
        checkAuthentication,
        getSageAccessToken,
        getAccessToken,
        sageSignout,
        sageSignin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import ListGroup from "react-bootstrap/ListGroup";
import { useState, useEffect } from "react";
import { CheckAll, X } from "react-bootstrap-icons";
import { useUpdate, useFetch } from "../../db/dbHooks";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import SubmitBT from "../../components/buttons/SubmitBT";
import { getFromLocalDB, storeInLocalDB } from "../../fun/fun";
import { usePopup } from "../floating/PopupContext";
import Loader from "../loader/Loader";
import QtyEditor from "../createOder/QtyEditor";
import "./dispatch.css";

const DispatchProductMiddle = ({ selectedProduct, update, setLock }) => {
  const { showPopupSuccess, showPopupError } = usePopup();
  const { data, loading, error, refetch } = useFetch(
    `orderDetails/orderDetailsByProduct/${selectedProduct.productID}`
  );
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("orderDetails");
  const [qtyToEdit, setQtyToEdit] = useState(null);

  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [batchNo, setBatchNo] = useState("");
  const [customers, setCustomers] = useState(null);
  const [batchNoHint, setBatchNoHint] = useState(
    getFromLocalDB("productID:" + selectedProduct.productID)
  );
  //Fetch results handler
  useEffect(() => {
    if (data) {
      setCustomers(data);
    } else if (error) {
      showPopupError(error);
    }
    setBatchNoHint(getFromLocalDB("productID:" + selectedProduct.productID));
  }, [data, error]);

  //update results handler
  useEffect(() => {
    if (updateResponse) {
      showPopupSuccess(updateResponse.message);
      setLock(false);
      refetch();
    } else if (updateError) {
      showPopupError(updateError);
    }
  }, [updateResponse, updateError]);

  // calculate qty when a new change detected
  useEffect(() => {
    let sum = 0;
    customers?.map((item) => {
      if (item.selected) {
        sum += item.dispatchQTY
          ? Number(item.dispatchQTY)
          : Number(item.quantity);
      }
    });

    setSelectedQuantity(sum);
  }, [customers]);

  // reset state when new product is picked
  useEffect(() => {
    setBatchNoHint(getFromLocalDB("productID:" + selectedProduct.productID));
    setBatchNo("");
  }, [selectedProduct]);

  const handleSubmit = () => {
    const orderDetails = [];
    customers.map((customer) => {
      const modifiedCustomer = {};

      if (customer.selected) {
        modifiedCustomer["batch"] = batchNo;
      }
      if (customer.editedQuantity) {
        modifiedCustomer["dispatchQTY"] = customer.dispatchQTY;
      }
      if (customer.selected || customer.editedQuantity) {
        modifiedCustomer["orderDetailsID"] = customer.orderDetailsID;
        orderDetails.push(modifiedCustomer);
      }
    });

    if (orderDetails.length > 0) {
      updateData(null, { orderDetails });
      if (batchNo !== "")
        storeInLocalDB("productID:" + selectedProduct.productID, batchNo);
    } else {
      showPopupError("No changes detected");
    }
  };

  const handleQtyUpdate = (product) => {
    if (product?.editedQuantity) {
      setLock(true);
      setCustomers(
        customers.map((items) => {
          if (items.customerID === product.customerID) {
            return product;
          } else {
            return items;
          }
        })
      );
    }
    setQtyToEdit(false);
  };

  const handleCustomerSelection = (selectedCustomer) => {
    setCustomers(
      customers.map((customer) => {
        if (customer.customerID === selectedCustomer.customerID) {
          return selectedCustomer.selected
            ? { ...selectedCustomer, selected: false }
            : { ...selectedCustomer, selected: true };
        } else {
          return customer;
        }
      })
    );
  };

  return (
    <div className="dispatch_middle_container">
      <h3>{selectedProduct.productName}</h3>
      {loading ? (
        <Loader color={"black"} />
      ) : (
        <>
          <h6>Ordered By</h6>
          <div className="product_middle_list">
            <ListGroup horizontal className="list-header sticky">
              <ListGroup.Item>Customer</ListGroup.Item>
              <ListGroup.Item>Route</ListGroup.Item>
              <ListGroup.Item>Batch</ListGroup.Item>
              <ListGroup.Item>Qty</ListGroup.Item>
            </ListGroup>
            {customers?.map((item, index) => (
              <ListGroup horizontal key={index}>
                <ListGroup.Item
                  active={item.selected}
                  onClick={() => handleCustomerSelection(item)}
                >
                  {item.customerName}
                </ListGroup.Item>
                <ListGroup.Item active={item.selected}>
                  {item.routeName}
                </ListGroup.Item>

                <ListGroup.Item active={item.selected}>
                  {item.batch}
                </ListGroup.Item>

                <ListGroup.Item
                  onClick={() => setQtyToEdit(item)}
                  className={item.editedQuantity ? "edited" : ""}
                >
                  {item.dispatchQTY
                    ? Number(item.dispatchQTY)
                    : Number(item.quantity)}
                </ListGroup.Item>
              </ListGroup>
            ))}
          </div>
          <div id="product_middle_select_bt">
            <X
              size={40}
              onClick={() =>
                setCustomers(
                  customers.map((customer) => {
                    return { ...customer, selected: false };
                  })
                )
              }
            />

            <CheckAll
              size={40}
              color="black"
              onClick={() =>
                setCustomers(
                  customers.map((customer) => {
                    return { ...customer, selected: true };
                  })
                )
              }
            />
          </div>
          {batchNoHint && (
            <div id="product_middle_hint_container">
              <p>Last Batch Used:</p>
              <button onClick={() => setBatchNo(batchNoHint)}>
                {batchNoHint}
              </button>
            </div>
          )}

          <InputGroup className="mb-3 mt-3">
            <InputGroup.Text id="inputGroup-sizing-default">
              Batch No for {selectedQuantity + selectedProduct.unit}:
            </InputGroup.Text>
            <Form.Control
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              onChange={(e) => setBatchNo(e.target.value)}
              value={batchNo}
            />
          </InputGroup>

          {qtyToEdit && (
            <QtyEditor
              onSubmit={handleQtyUpdate}
              product={{ ...qtyToEdit, ...selectedProduct }}
            />
          )}

          <SubmitBT
            BTname="Save Changes"
            onClick={handleSubmit}
            loading={updateLoading}
          />
        </>
      )}
    </div>
  );
};

export default DispatchProductMiddle;

import ListGroup from "react-bootstrap/ListGroup";
import { useFetch } from "../../db/dbHooks";
import Loader from "../loader/Loader";
import { useConfirmation } from "../floating/ConfirmationPopupContext";
import "./dispatch.css";
import RefreshBT from "../buttons/RefreshBT";

const DispatchQuantityList = ({ activeItem, setclickedItem, isLocked }) => {
  const { showConfirmation } = useConfirmation();

  const { data, loading, error, refetch } = useFetch(
    "orderDetails/totalQuantity"
  );
  return !loading ? (
    <div id="dispatch_quantity_list_container">
      {error && (
        <>
          <p>{error}</p>
          <RefreshBT onClick={refetch} loading={loading} />
        </>
      )}
      {data?.map((item, index) => (
        <ListGroup
          horizontal
          key={index}
          onClick={() => {
            isLocked
              ? showConfirmation(
                  "Unsaved changes detected. Do you still want to procced?",
                  () => setclickedItem(item),
                  "Confirm"
                )
              : setclickedItem(item);
          }}
        >
          <ListGroup.Item
            active={activeItem?.product?.productID === item.productID}
          >
            {item.productName}
          </ListGroup.Item>
          <ListGroup.Item
            active={activeItem?.product?.productID === item.productID}
          >
            {Number(item.totalQuantity)}
          </ListGroup.Item>
        </ListGroup>
      ))}
    </div>
  ) : (
    <div className="text-center">
      <Loader color={"black"} />
    </div>
  );
};

export default DispatchQuantityList;

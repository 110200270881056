import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useEffect, useState, useRef, useContext } from "react";
import SubmitBT from "../buttons/SubmitBT";
import { usePost, useUpdate } from "../../db/dbHooks";
import DropdownTab from "../dropdown/DropdownTab";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { usePopup } from "../floating/PopupContext";
import { AuthContext } from "../../fun/authContext";

export default function Product({ productToEdit = null, reFetch, category }) {
  const { user } = useContext(AuthContext);
  const defaultProduct = {
    sageID: null,
    productName: "",
    unit: "",
    price: 0,
    categoryID: "",
    costPrice: 0,
  };
  const { showPopupSuccess, showPopupError } = usePopup();
  const [sageRequest, setSageRequest] = useState(
    true
    /*  user.sageLoginState
      ? productToEdit
        ? productToEdit.sageID
          ? true
          : false
        : true
      : false */
  );
  const [validated, setValidated] = useState(false);
  const priceRef = useRef(null);
  const costPriceRef = useRef(null);

  const [product, setProduct] = useState(
    productToEdit ? productToEdit : defaultProduct
  );
  const { postResponse, postLoading, postError, postData } =
    usePost("products");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("products");

  useEffect(() => {
    if (!productToEdit) resetState();
  }, [productToEdit]);

  useEffect(() => {
    if (updateError) {
      showPopupError(updateError);
    } else if (updateResponse) {
      resetState();
      showPopupSuccess(updateResponse.message);
    }
  }, [updateResponse, updateError]);
  useEffect(() => {
    if (postError) {
      showPopupError(postError);
    } else if (postResponse) {
      resetState();
      showPopupSuccess(postResponse.message);
    }
  }, [postResponse, postError]);
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (productToEdit) {
        if (JSON.stringify(productToEdit) !== JSON.stringify(product)) {
          updateData(
            sageRequest ? product.sageID : product.productID,
            {
              product,
            },
            sageRequest
          );
        } else {
          showPopupError("No Changes Were Made");
        }
      } else {
        postData({ product }, sageRequest);
      }
    }
    setValidated(true);
  };
  const resetState = () => {
    setValidated(false);
    reFetch();
    setProduct(defaultProduct);
  };

  const handleCategorySelection = (event) => {
    const id = Number(event.target.value);
    const selectedOption = category.find(
      (category) => category.categoryID === id
    ); // Find the object
    setProduct({
      ...product,
      categoryID: selectedOption.categoryID,
      categoryCode: selectedOption.categoryCode,
    });
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="form"
    >
      <Form.Check // prettier-ignore
        style={{ marginLeft: "auto", color: "green" }}
        disabled={true}
        checked={true}
        onChange={() => setSageRequest(!sageRequest)}
        type="switch"
        id="custom-switch"
        label={productToEdit ? "Update Sage" : "Add To Sage"}
        reverse
      />
      <DropdownTab title={"Product Information"} open={true}>
        <Row>
          {/*    <Form.Group as={Col}>
            <Form.Label>Code</Form.Label>
            <Form.Control
              id="unit"
              placeholder="Optional"
              required
              value={product.unit}
              onChange={(e) => setProduct({ ...product, unit: e.target.value })}
            />
          </Form.Group> */}
          <Form.Group as={Col}>
            <Form.Label>Product Name / Display As</Form.Label>
            <Form.Control
              id="productName"
              type="text"
              autoComplete="off"
              placeholder="Enter product name"
              value={product.productName}
              required
              onChange={(e) =>
                setProduct({ ...product, productName: e.target.value })
              }
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Category</Form.Label>
            <Form.Select
              id="categoryID"
              value={product.categoryID || ""}
              required
              isInvalid={validated && product.categoryID === ""}
              onChange={handleCategorySelection}
            >
              <option value={""}>Choose Category</option>
              {category
                ? category.map((category) => (
                    <option
                      key={category.categoryID}
                      value={category.categoryID}
                    >
                      {category.categoryName}
                    </option>
                  ))
                : ""}
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Price</Form.Label>
            <Form.Control
              id="price"
              required
              placeholder="Optional - Example: 11.4"
              type="number"
              value={Number(product.price)}
              ref={priceRef}
              onFocus={() => priceRef.current.select()}
              onChange={(e) =>
                setProduct({ ...product, price: Number(e.target.value) })
              }
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Unit</Form.Label>
            <Form.Control
              id="unit"
              placeholder="Optional"
              value={product.unit || ""}
              onChange={(e) => setProduct({ ...product, unit: e.target.value })}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Ledger account</Form.Label>
            <Form.Select disabled>
              <option value={""}>Admin Default</option>
            </Form.Select>
          </Form.Group>
        </Row>
      </DropdownTab>

      <DropdownTab title={"Supplier Information"}>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Cost Price</Form.Label>
            <Form.Control
              id="cost_price"
              required={sageRequest}
              disabled={!sageRequest}
              type="number"
              value={Number(product.costPrice) || 0}
              ref={costPriceRef}
              onFocus={() => costPriceRef.current.select()}
              onChange={(e) =>
                setProduct({ ...product, costPrice: Number(e.target.value) })
              }
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Purchase Account</Form.Label>
            <Form.Select disabled>
              <option value={""}>Admin Default</option>
            </Form.Select>
          </Form.Group>
        </Row>
      </DropdownTab>

      <SubmitBT
        BTname={productToEdit ? "Update Product" : "Add Product"}
        loading={postLoading || updateLoading}
      />
    </Form>
  );
}

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import LogoutBT from "../buttons/LogoutBT";
import { usePost, useUpdate } from "../../db/dbHooks";
import SubmitBT from "../buttons/SubmitBT";
import { usePopup } from "../floating/PopupContext";
import { useConfirmation } from "../floating/ConfirmationPopupContext";

export default function Register({
  userToEdit = null,
  viewOnly = false,
  reFetch,
}) {
  const { showPopupSuccess, showPopupError } = usePopup();
  const { showConfirmation } = useConfirmation();

  const checkBoxes = [
    { ID: "Admin" },
    { ID: "Orders" },
    { ID: "Dispatch" },
    { ID: "Driver" },
  ];

  const defaultUser = {
    email: "",
    username: "",
    password: "",
    confirmPass: userToEdit?.password || "",
    userType: "",
    active: true,
    adminPass: "",
    ...userToEdit,
  };
  const [validated, setValidated] = useState(false);
  const [userToAdd, setUserToAdd] = useState(defaultUser);
  const { postResponse, postLoading, postError, postData } =
    usePost("auth/register");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("auth/user");

  useEffect(() => {
    if (updateError) {
      showPopupError(updateError);
    } else if (updateResponse) {
      reFetch();
      showPopupSuccess(updateResponse.message);
      resetState();
    }
  }, [updateResponse, updateError]);

  useEffect(() => {
    if (postError) {
      showPopupError(postError);
    } else if (postResponse) {
      reFetch();
      showPopupSuccess(postResponse.message);
      resetState();
    }
  }, [postResponse, postError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (userToAdd.password !== userToAdd.confirmPass)
        return showPopupError("Passwords not matching");
      if (userToEdit) {
        updateData(userToAdd.userID, { userToUpdate: userToAdd });
      } else {
        postData({ userToAdd });
      }
    }

    setValidated(true);
  };

  const resetState = () => {
    setUserToAdd(defaultUser);
    setValidated(false);
  };

  return (
    <Form
      noValidate
      validated={validated}
      className="form"
      onSubmit={handleSubmit}
    >
      {userToEdit && (
        <Form.Check
          type="checkbox"
          label={"Account Active"}
          reverse
          checked={userToAdd.active}
          onChange={(e) =>
            setUserToAdd({ ...userToAdd, active: e.target.checked })
          }
          disabled={viewOnly}
        />
      )}
      <Row>
        <Form.Group as={Col} controlId="userName" className="form_field">
          <Form.Label>Username</Form.Label>
          <Form.Control
            autoComplete="off"
            minLength={5}
            required
            type="text"
            placeholder="E.g: John123"
            value={userToAdd.username}
            onChange={(e) =>
              setUserToAdd({ ...userToAdd, username: e.target.value })
            }
            disabled={viewOnly}
          />
        </Form.Group>
        <Form.Group as={Col} className="form_field">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            autoComplete="off"
            required
            type="email"
            placeholder="example@gmail.com"
            value={userToAdd.email || ""}
            onChange={(e) =>
              setUserToAdd({ ...userToAdd, email: e.target.value })
            }
            disabled={viewOnly}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group
          as={Col}
          controlId="password"
          hidden={viewOnly}
          className="form_field"
        >
          <Form.Label>Password</Form.Label>
          <Form.Control
            autoComplete="off"
            minLength={8}
            required={!userToEdit}
            type="password"
            placeholder="Password"
            value={userToAdd.password}
            onChange={(e) =>
              setUserToAdd({ ...userToAdd, password: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="confirmPassword"
          hidden={viewOnly}
          className="form_field"
        >
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            autoComplete="off"
            minLength={8}
            required={!userToEdit}
            type="password"
            placeholder="Confirm Password"
            value={userToAdd.confirmPass}
            onChange={(e) =>
              setUserToAdd({ ...userToAdd, confirmPass: e.target.value })
            }
          />
        </Form.Group>
      </Row>
      <Form.Group as={Col} controlId="AdminPass" hidden={viewOnly}>
        <Form.Label>Admin Password</Form.Label>
        <Form.Control
          minLength={8}
          required
          type="password"
          placeholder="Admin Password"
          value={userToAdd.adminPass}
          onChange={(e) =>
            setUserToAdd({ ...userToAdd, adminPass: e.target.value })
          }
        />
      </Form.Group>
      <Form.Label>Role:</Form.Label>
      <Row className="justify-content-around">
        {checkBoxes.map((box) => (
          <Form.Check
            key={box.ID}
            className="w-auto"
            type="checkbox"
            id={box.ID}
            label={box.ID}
            required={userToAdd.userType === ""}
            checked={userToAdd.userType === box.ID}
            onChange={(e) =>
              setUserToAdd({ ...userToAdd, userType: e.target.id })
            }
            disabled={viewOnly}
          />
        ))}
      </Row>

      <Row>
        <SubmitBT
          BTname={userToEdit ? "Update User" : "Add User"}
          loading={postLoading || updateLoading}
          hidden={viewOnly}
        />{" "}
      </Row>

      <div style={{ margin: "auto" }} hidden={!viewOnly}>
        <LogoutBT />
      </div>
    </Form>
  );
}

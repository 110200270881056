import Spinner from "react-bootstrap/Spinner";
import { XCircle, CheckCircle } from "react-bootstrap-icons";

function LoadingSpinner({ type = "loading", message = "Loading...." }) {
  return (
    <div
      className="fixed-bg"
      style={{
        flexDirection: "column",
        rowGap: "10px",
        color: "white",
        textAlign: "center",
      }}
    >
      {type === "Success" ? (
        <CheckCircle size={40} color="Green" />
      ) : type === "Error" ? (
        <XCircle size={40} color="red" />
      ) : (
        <Spinner animation="border" role="status" variant="light">
          <span className="visually-hidden">Loading....</span>
        </Spinner>
      )}

      <h6>{message}</h6>
    </div>
  );
}

export default LoadingSpinner;

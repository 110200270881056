import {
  PeopleFill,
  ExclamationCircleFill,
  BuildingFill,
  Upc,
  PersonFill,
  Link,
} from "react-bootstrap-icons";
import { Outlet } from "react-router-dom";

import "./Settings.css";
import SidePanel from "../../components/sidePanel/SidePanel";
export default function Settings() {
  const panelItems = [
    [{ icon: PersonFill, name: "Acount" }],

    [
      { icon: Link, name: "Sage" },
      { icon: PeopleFill, name: "Users" },
      { icon: Upc, name: "Barcodes" },
    ],

    [
      { icon: BuildingFill, name: "Company" },
      { icon: ExclamationCircleFill, name: "About" },
    ],
  ];

  return (
    <div className="page-full">
      <div className="settings-main">
        <SidePanel items={panelItems} />
        <div className="settings-right-side">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

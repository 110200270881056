import CreateOrder from "../../components/createOder/CreateOrder";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useState, useEffect } from "react";
import { useFetch, usePost, useUpdate } from "../../db/dbHooks";
import { useLocation } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Filter from "../../components/filter/Filter";
import Loader from "../../components/loader/Loader";
import { usePopup } from "../../components/floating/PopupContext";
import { ddmmyy, euDate } from "../../fun/fun";

export default function ActiveOrders() {
  const { showPopupSuccess, showPopupError } = usePopup();

  const {
    data: orders,
    loading: productsLoading,
    error: productsError,
    refetch: productsRefetch,
  } = useFetch("orders/activeOrders");
  /*   // error handling
  useEffect(() => {
    if (updateResponse || postResponse) {
      showPopupSuccess(
        postResponse
          ? postResponse.message
          : updateResponse && updateResponse.message
      );
      setOrderItems([]);
    } else if (postError || updateError) {
      showPopupError(postError || postError);
    }
  }, [updateResponse, updateError, postResponse, postError]); */

  return (
    <div className="page-main">
      <PageTitle title={"Active Orders"} />
      {/* TODO add count header */}
      <ListHeader />
      <ListBody data={orders} />
    </div>
  );
}

const ListHeader = () => {
  return (
    <>
      <ListGroup horizontal className="list-header">
        <ListGroup.Item style={{ width: "25%" }}>Delivery Date</ListGroup.Item>
        <ListGroup.Item style={{ width: "50%" }}>Customer</ListGroup.Item>
        <ListGroup.Item style={{ width: "25%" }}>Status</ListGroup.Item>
      </ListGroup>
    </>
  );
};

const ListBody = ({ data = [] }) => {
  if (data?.length === 0) {
    return <p>No Active Orders</p>;
  }
  return data?.map(({ deliveryDate, customerName, status, orderID }) => (
    <ListGroup horizontal key={orderID} className="clickable_list_item">
      <ListGroup.Item style={{ width: "25%" }}>
        {ddmmyy(deliveryDate)}
      </ListGroup.Item>
      <ListGroup.Item style={{ width: "50%" }}>{customerName}</ListGroup.Item>
      <ListGroup.Item
        style={{
          width: "25%",
          backgroundColor:
            status === "New Order" || status === "Updated Order"
              ? "#85abff"
              : status === "Completed"
              ? "#95ff85"
              : status === "No Order"
              ? "#a3a3a3"
              : "#fff091",
        }}
      >
        {status}
      </ListGroup.Item>
    </ListGroup>
  ));
};

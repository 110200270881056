import ListGroup from "react-bootstrap/ListGroup";
import { useState } from "react";
import "./List.css";
import Filter from "../filter/Filter";

export default function UserList({ userData, setUserToEdit }) {
  const [search, setSearch] = useState("");

  return (
    <div className="list-main">
      <Filter setSearch={setSearch} />

      <ListGroup horizontal className="product-list list-header">
        <ListGroup.Item className="item1 w-75">Username</ListGroup.Item>
        <ListGroup.Item className="item2-3 w-25">Role</ListGroup.Item>
      </ListGroup>
      {userData?.map(
        (user) =>
          (user.username.search(new RegExp(search, "i")) !== -1 ||
            search === "") && (
            <ListGroup
              className="clickable_list_item"
              horizontal
              key={user.userID}
              onClick={() => setUserToEdit(user)}
            >
              <ListGroup.Item
                className="item1 w-75"
                style={{ backgroundColor: !user.active ? "lightgray" : "" }}
              >
                {user.username}
              </ListGroup.Item>
              <ListGroup.Item
                className="item2-3 w-25"
                style={{ backgroundColor: !user.active ? "lightgray" : "" }}
              >
                {user.userType}
              </ListGroup.Item>
            </ListGroup>
          )
      )}
    </div>
  );
}

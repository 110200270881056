import "./App.css";
import Login from "./pages/login/Login";
import NavBar from "./components/navBar/NavBar";
import { Routes, Route } from "react-router-dom";
import ManageProducts from "./pages/manage/ManageProduts";
import Home from "./pages/home/Home";
import ManageRoutes from "./pages/manage/ManageRoutes";
import ManageCategories from "./pages/manage/ManageCategories";
import ManageCustomers from "./pages/manage/ManageCustomers";
import NewOrder from "./pages/order/NewOrder";
import OrderHistory from "./pages/order/OrderHistory";
import { useContext, useState, useEffect } from "react";
import ManageUsers from "./pages/settings/ManageUsers.js";
import Profile from "./pages/login/Profile";
import Dispatch from "./pages/dispatch/Dispatch.js";
import Report from "./pages/dispatch/Report.js";
import ManageVans from "./pages/manage/ManageVans.js";
import "./BootstrapStyle.scss";
import CheckOnlineStatus from "./components/navBar/CheckOnlineStatus.js";
import Settings from "./pages/settings/Settings.js";
import Sage from "./pages/settings/Sage.js";
import About from "./pages/settings/About.js";
import Barcodes from "./pages/settings/Barcodes.js";
import NewInvoice from "./pages/order/NewInvoice.js";
import Drivers from "./pages/drivers/Drivers.js";
import ActiveOrders from "./pages/order/ActiveOrders.js";
import PostOrders from "./pages/dispatch/PostOrders.js";
import SageSignin from "./components/navBar/SageSignin.js";
import { AuthContext } from "./fun/authContext.js";

function App() {
  const { isAuth, checkAuthentication, user, signout } =
    useContext(AuthContext);

  //Sage Token Handler
  useEffect(() => {
    checkAuthentication();
  }, []);

  useEffect(() => {
    const handleWindowClose = () => {
      if (!user.stayLoggedin) signout();
    };
    window.addEventListener("beforeunload", handleWindowClose);
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, []);

  const DefaultPage = (key) => {
    switch (key) {
      case "Admin":
        return <Home />;
      case "Order":
        return <Home />;

      case "Dispatch":
        return <Dispatch />;
    }
  };

  return (
    <div className="App">
      {isAuth ? (
        <>
          <NavBar />
          {user?.userType === "Orders" && <SageSignin />}
          <CheckOnlineStatus />
          <Routes>
            <Route path="*" element={DefaultPage(user.userType)} />
            {user.userType !== "Dispatch" && user.userType !== "Driver" && (
              <>
                <Route path="/" element={<Home user={user} />} />
                <Route path="/ManageRoutes" element={<ManageRoutes />} />
                <Route path="/ManageProducts" element={<ManageProducts />} />
                <Route
                  path="/ManageCategories"
                  element={<ManageCategories />}
                />
                <Route path="/ManageCustomers" element={<ManageCustomers />} />
                <Route path="/ManageVans" element={<ManageVans />} />
                <Route path="/NewInvoice" element={<NewInvoice />} />
                <Route path="/ActiveOrders" element={<ActiveOrders />} />
                <Route path="/NewOrder" element={<NewOrder />} />
                <Route path="/OrderHistory" element={<OrderHistory />} />
                <Route path="/Reports" element={<Report />} />
                <Route path="/PostOrders" element={<PostOrders />} />
              </>
            )}
            {user.userType !== "Driver" && (
              <Route path="/Drivers" element={<Drivers />} />
            )}

            <Route path="/Dispatch" element={<Dispatch />} />

            {user.userType === "Admin" && (
              <Route path="/Settings/*" element={<Settings />}>
                <Route path="Sage" element={<Sage />} />
                <Route path="Users" element={<ManageUsers />} />
                <Route path="About" element={<About />} />
                <Route path="Barcodes" element={<Barcodes />} />
              </Route>
            )}
            <Route path="/Profile" element={<Profile userData={user} />} />
          </Routes>
        </>
      ) : (
        <Login />
      )}
    </div>
  );
}
export default App;

import FormTab from "../../components/forms/FormTab.js";
import Route from "../../components/forms/Route.js";
import PageTitle from "../../components/pageTitle/PageTitle.js";
import { useState, useEffect, useMemo } from "react";
import { useFetch, useDelete } from "../../db/dbHooks";
import ListGroup from "react-bootstrap/ListGroup";
import Filter from "../../components/filter/Filter.js";
import { useConfirmation } from "../../components/floating/ConfirmationPopupContext.js";
import { usePopup } from "../../components/floating/PopupContext.js";
export default function ManageRoutes() {
  const { showConfirmation } = useConfirmation();
  const { showPopupSuccess, showPopupError } = usePopup();

  const [selectedTab, setSelectedTab] = useState(null);
  const [routeToEdit, setRouteToEdit] = useState(null);
  const { data: routes, loading, error, refetch } = useFetch("routes");
  const { deleteResponse, deleteLoading, deleteError, deleteData } =
    useDelete("routes");
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    setRouteToEdit(null);
  }, [selectedTab]);

  //handle delete request
  useEffect(() => {
    if (deleteResponse) {
      refetch();
      showPopupSuccess(deleteResponse.message);
    } else if (deleteError) {
      showPopupError(deleteError);
    }
  }, [deleteResponse, deleteError]);

  const onUpdate = () => {
    refetch();
    setRouteToEdit(null);
  };

  const handleDelete = (id) => {
    deleteData(id);
  };

  const handleShowPopup = () => {
    setShowPopup(false);
    deleteResponse && refetch();
  };

  return (
    <div className="form-page">
      <PageTitle title={"Manage Routes"} />
      <FormTab tabs={["New Route", "Edit Route"]} selected={setSelectedTab} />

      {selectedTab === "New Route" ? (
        <Route route={null} triggerUpdate={onUpdate} />
      ) : selectedTab === "Edit Route" && routeToEdit === null ? (
        <div className="manage-list-container">
          <RouteList
            routes={routes}
            setRouteToEdit={setRouteToEdit}
            setRouteToDelete={(route) =>
              showConfirmation(
                "Are you sure you want to delete " + route.routeName,
                () => handleDelete(route.routeID),
                "Delete"
              )
            }
          />
        </div>
      ) : routeToEdit ? (
        <Route routeToEdit={routeToEdit} triggerUpdate={onUpdate} />
      ) : (
        ""
      )}
    </div>
  );
}

export const RouteList = ({
  routes = null,
  setRouteToEdit,
  setRouteToDelete,
}) => {
  const [clickedItem, setClickedItem] = useState(-1);
  const [search, setSearch] = useState("");

  // Memoize filtered routes for performance optimization
  const filteredRoutes = useMemo(() => {
    const searchRegex = new RegExp(search, "i");
    return (
      routes &&
      routes.filter(
        (route) => search === "" || searchRegex.test(route.routeName)
      )
    );
  }, [search, routes]);

  return routes?.length > 0 ? (
    <div className="list-container">
      <div className="sticky">
        <Filter setSearch={setSearch} />
        <ListGroup className="list-header">
          <ListGroup.Item>Route Name</ListGroup.Item>
        </ListGroup>
      </div>
      <ListGroup>
        {filteredRoutes.length > 0 ? (
          filteredRoutes.map(({ routeID, routeName, route }, index) => (
            <div key={routeID}>
              <ListGroup.Item
                className="clickable_list_item"
                onClick={() =>
                  setClickedItem(clickedItem === index ? -1 : index)
                }
              >
                {routeName}
              </ListGroup.Item>
              {clickedItem === index && (
                <div>
                  <ListGroup.Item
                    className="modify-list-item"
                    onClick={() => setRouteToEdit(filteredRoutes[index])}
                  >
                    {"Modify " + routeName}
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="delete-list-item"
                    onClick={() => setRouteToDelete({ routeID, routeName })}
                  >
                    {"Delete " + routeName}
                  </ListGroup.Item>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No routes match your search</p>
        )}
      </ListGroup>
    </div>
  ) : (
    <p>No Routes Added</p>
  );
};

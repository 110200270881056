import ListGroup from "react-bootstrap/ListGroup";
import { useMemo, useState, memo } from "react";
import "./List.css";
import Filter from "../filter/Filter";

function ProductList({
  productData,
  setProductToDelete,
  setProductToEdit,
  disableListMenu = false,
  disableCenters = false,
}) {
  const [search, setSearch] = useState("");
  const [clickedItem, setClickedItem] = useState(-1);

  // Memoize filtered data to improve performance
  const filteredProducts = useMemo(() => {
    const searchRegex = new RegExp(search, "i");

    return productData?.filter((product) => {
      // Exclude products with the "centers" category if disableCenters is true
      if (disableCenters && product.categoryName === "Centers") {
        return false;
      }

      // Apply the existing search logic
      return (
        search === "" ||
        searchRegex.test(product.productName) ||
        searchRegex.test(product.categoryName)
      );
    });
  }, [search, productData, disableCenters]);

  return (
    <div className="list-container">
      {productData?.length > 0 ? (
        <>
          <div className="sticky">
            <Filter setSearch={setSearch} />
            <ListGroup horizontal className="list-header">
              <ListGroup.Item className="item1">Product Name</ListGroup.Item>
              <ListGroup.Item className="item2-3 w-20">Category</ListGroup.Item>
              <ListGroup.Item className="item2-3 w-20">Price</ListGroup.Item>
            </ListGroup>
          </div>
          <div className="list-body">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product, index) => (
                <div key={product.productID}>
                  <ListGroup
                    className="clickable_list_item"
                    horizontal
                    onClick={() =>
                      disableListMenu
                        ? setProductToEdit(product)
                        : setClickedItem(clickedItem === index ? -1 : index)
                    }
                  >
                    <ListGroup.Item className="item1 d-flex align-items-center">
                      <div
                        style={{
                          borderRadius: "25px",
                          backgroundColor: product.sageID && "Green",
                          width: "7px",
                          height: "7px",
                          marginRight: "5px",
                          marginLeft: "-5px",
                        }}
                      />
                      {product.productName}
                    </ListGroup.Item>
                    <ListGroup.Item className="item2-3 w-20">
                      {product.categoryName || "Unset"}
                    </ListGroup.Item>
                    <ListGroup.Item className="item2-3 w-20">
                      {Number(product.price)}
                    </ListGroup.Item>
                  </ListGroup>
                  {clickedItem === index && (
                    <div>
                      <ListGroup>
                        <ListGroup.Item
                          className="modify-list-item"
                          onClick={() => setProductToEdit(product)}
                        >
                          {"Modify " + product.productName}
                        </ListGroup.Item>
                        <ListGroup.Item
                          className="delete-list-item"
                          onClick={() => setProductToDelete(product)}
                        >
                          {"Delete " + product.productName}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p className="text-center mt-4">No products found</p>
            )}
          </div>
        </>
      ) : (
        <p className="text-center mt-4">No products added</p>
      )}
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.productData) ===
    JSON.stringify(nextProps.productData)
  );
}

// Export the component wrapped with React.memo
export default memo(ProductList, areEqual);

import { ArrowClockwise } from "react-bootstrap-icons";
import "./buttons.css";

export default function RefreshBT({ onClick, loading, errorMessage }) {
  return (
    <div>
      <p>{errorMessage}</p>
      <ArrowClockwise
        fontSize={"35"}
        fontWeight={"bold"}
        onClick={onClick}
        className={loading ? "rotate-center" : ""}
      />
    </div>
  );
}

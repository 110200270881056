// context/PopupContext.js
import React, { createContext, useState, useContext } from "react";
import { ConfirmationPopup } from "./ConfirmationPopup";

const ConfirmationPopupContext = createContext();

export const useConfirmation = () => useContext(ConfirmationPopupContext);

export const ConfirmationPopupProvider = ({ children }) => {
  const [confirmationProps, setConfirmationProps] = useState({
    btName: null,
    isConfirmationVisible: false,
    promptMessage: "",
    onConfirm: () => {},
  });

  const showConfirmation = (promptMessage, onConfirm, btName) => {
    setConfirmationProps({
      btName: btName,
      isConfirmationVisible: true,
      promptMessage,
      onConfirm,
    });
  };

  const hideConfirmationPopup = () => {
    setConfirmationProps({
      isConfirmationVisible: false,
      promptMessage: "",
      onConfirm: () => {},
    });
  };

  return (
    <ConfirmationPopupContext.Provider
      value={{ showConfirmation, hideConfirmationPopup }}
    >
      {children}
      {confirmationProps.isConfirmationVisible && (
        <ConfirmationPopup
          btName={confirmationProps.btName}
          onClose={hideConfirmationPopup}
          onConfirm={() => confirmationProps.onConfirm()}
          message={confirmationProps.promptMessage}
        />
      )}
    </ConfirmationPopupContext.Provider>
  );
};

import FormTab from "../../components/forms/FormTab";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useState, useEffect } from "react";
import Category from "../../components/forms/Category";
import { useFetch, useDelete } from "../../db/dbHooks";
import Loader from "../../components/loader/Loader";
import CategoryList from "../../components/list/CategoryList";
import { useConfirmation } from "../../components/floating/ConfirmationPopupContext.js";
import { usePopup } from "../../components/floating/PopupContext.js";

export default function ManageCategories() {
  const { showConfirmation } = useConfirmation();
  const { showPopupSuccess, showPopupError } = usePopup();
  const [selectedTab, setSelectedTab] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const { deleteResponse, deleteLoading, deleteError, deleteData } =
    useDelete("categories");
  const { data, loading, error, refetch } = useFetch("categories");

  useEffect(() => {
    setCategoryToEdit(null);
  }, [selectedTab]);

  useEffect(() => {
    if (deleteResponse) {
      showPopupSuccess(deleteResponse.message);
      refetch();
    } else if (deleteError) {
      showPopupError(deleteError);
    }
  }, [deleteResponse, deleteError]);

  const onUpdate = () => {
    refetch();
    setCategoryToEdit(null);
  };

  return (
    <div className="form-page">
      <PageTitle title={"Manage Categories"} />
      <FormTab
        tabs={["New Category", "Edit Category"]}
        selected={setSelectedTab}
      />

      {selectedTab === "New Category" ? (
        <Category reFetch={onUpdate} />
      ) : selectedTab === "Edit Category" && categoryToEdit === null ? (
        loading ? (
          <Loader color={"black"} />
        ) : (
          <div className="manage-list-container">
            <CategoryList
              categoryData={data}
              updateData={onUpdate}
              setcategoryToEdit={setCategoryToEdit}
              setcategoryToDelete={(category) =>
                showConfirmation(
                  "Are you sure you want to delete " + category.categoryName,
                  () => deleteData(category.categoryID),
                  "Delete"
                )
              }
            />
          </div>
        )
      ) : categoryToEdit ? (
        <Category categoryToEdit={categoryToEdit} reFetch={onUpdate} />
      ) : (
        ""
      )}
    </div>
  );
}

import { useState, useEffect } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useFetch } from "../../db/dbHooks";
import DispatchSheet from "../../components/dispatch/DispatchSheet";
import DispatchSheetForm from "../../components/forms/DispatchSheetForm";
import Popup from "../../components/floating/Popup";

export default function Report() {
  const [routeDetails, setRouteDetails] = useState(null);
  const [popup, setPopup] = useState(false);
  const [url, setUrl] = useState("");
  const {
    data: orders,
    loading: ordersLoading,
    error: ordersError,
    refetch: ordersRefetch,
  } = useFetch(url);

  useEffect(() => {
    if (ordersError) {
      setPopup(true);
    }
    setUrl("");
  }, [orders, ordersError]);

  const handleSubmit = (data) => {
    setRouteDetails(data);
    setUrl(`orders/allorderwithdetails/${data.routeID}?date=${data.date}`);
  };

  return (
    <div>
      <PageTitle title={"Dispatch Summry"} />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <DispatchSheetForm
          resultsLoading={ordersLoading}
          onSubmit={handleSubmit}
        />
      </div>
      {orders && <DispatchSheet orders={orders} routeDetails={routeDetails} />}
      {popup && (
        <Popup error={ordersError} setTrigger={() => setPopup(false)} />
      )}
    </div>
  );
}

import "./Login.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import logo from "./Icon.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useContext } from "react";
import SubmitBT from "../../components/buttons/SubmitBT.js";
import { AuthContext } from "../../fun/authContext.js";

//TODO - change get element by id to one state
export default function Login() {
  const { signin } = useContext(AuthContext);
  const [errorMassage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const username = document.getElementById("userName").value;
    const password = document.getElementById("pass").value;

    //check the length of pass and username
    if (password.length > 7 && username.length > 5) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "auth/login",
          {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              username,
              password,
              stayLoggedin: document.getElementById("rememberMe").checked,
            }),
          }
        );
        const responseData = await response.json();
        if (!response.ok) throw responseData.message;

        signin(responseData);
      } catch (err) {
        setErrorMessage(err);
      }
    } else {
      if (password.length === 0 || username.length === 0) {
        setErrorMessage("Username or password can't be empty");
      } else {
        if (password.length < 8)
          setErrorMessage("Password should be more than 7 letter");
        else setErrorMessage("Username should be more than 5 letter");
      }
    }
  };

  return (
    <div className="login">
      <img className="logo" src={logo} />
      <div className="form">
        <FloatingLabel controlId="userName" label="User Name" className="mb-3">
          <Form.Control
            type="text"
            placeholder="John123"
            onChange={() => setErrorMessage("")}
          />
        </FloatingLabel>
        <FloatingLabel controlId="pass" label="Password">
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={() => setErrorMessage("")}
          />
        </FloatingLabel>
        <Row>
          <Col>
            <Form.Check // prettier-ignore
              type="checkbox"
              id="rememberMe"
              label="Remember Me"
              style={{ textAlign: "left" }}
            />
          </Col>
        </Row>
        <p
          className="text-center"
          style={{
            color: "#ba0202",
            display: errorMassage !== "" ? "" : "none",
            margin: "auto",
          }}
        >
          {/*           {errorMassage}{" "}
           */}{" "}
        </p>

        <SubmitBT BTname="Sign in" onClick={handleSubmit} />
      </div>
    </div>
  );
}

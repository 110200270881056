import CreateOrder from "../../components/createOder/CreateOrder";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useState, useEffect } from "react";
import { useFetch, usePost } from "../../db/dbHooks";
import { usePopup } from "../../components/floating/PopupContext";
import Loader from "../../components/loader/Loader";

export default function NewInvoice() {
  const { showPopupSuccess, showPopupError } = usePopup();
  const [resetData, setResetData] = useState(false);
  const {
    data: products,
    loading: productsLoading,
    error: productsError,
    refetch: productsRefetch,
  } = useFetch("products/productsForOrder");
  const { postResponse, postLoading, postError, postData } =
    usePost("sage/sageInvoice");

  useEffect(() => {
    if (postResponse) {
      showPopupSuccess(postResponse.message);
      setResetData(!resetData);
    } else if (postError) {
      showPopupError(postError);
    }
  }, [postResponse, postError]);

  return (
    <div className="page-main">
      <PageTitle title={"New Invoice"} />
      {productsLoading ? (
        <Loader color={"black"} />
      ) : (
        <CreateOrder
          products={products?.products}
          categories={products?.categories}
          invoice={true}
          onSubmit={(e) => postData(e)}
          reset={resetData}
        />
      )}
    </div>
  );
}

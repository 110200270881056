import { useState, useEffect, useCallback } from "react";
import FormTab from "../../components/forms/FormTab";
import PageTitle from "../../components/pageTitle/PageTitle";
import Customer from "../../components/forms/Customer";
import CustomerList from "../../components/list/CustomerList";
import { useFetch, useDelete } from "../../db/dbHooks";
import "./Manage.css";
import Loader from "../../components/loader/Loader";
import { usePopup } from "../../components/floating/PopupContext";
import { useConfirmation } from "../../components/floating/ConfirmationPopupContext";

export default function ManageCustomers() {
  const { showConfirmation } = useConfirmation();
  const { showPopupSuccess, showPopupError } = usePopup();
  const [selectedTab, setSelectedTab] = useState("New Customer");
  const [customerToEdit, setCustomerToEdit] = useState(null);
  const { data, loading, error, refetch } = useFetch("customers");
  const [sageURL, setSageURL] = useState({ url: "", customer: null });

  const {
    data: sageCustomer,
    loading: sageLoading,
    error: sageError,
  } = useFetch(sageURL.url, true);
  const { deleteResponse, deleteLoading, deleteError, deleteData } =
    useDelete("customers");
  // Reset customerToEdit when the selectedTab changes
  useEffect(() => {
    setCustomerToEdit(null);
    setSageURL({ url: "", customer: null });
  }, [selectedTab]);

  useEffect(() => {
    if (sageError) {
      showPopupError(sageError);
    }
  }, [sageError]);

  // Show popup on delete response or error
  useEffect(() => {
    if (deleteResponse) {
      showPopupSuccess(deleteResponse.message);
      refetch();
    } else if (deleteError) {
      showPopupError(deleteError);
    }
  }, [deleteResponse, deleteError]);
  useEffect(() => {
    if (sageCustomer) {
      setCustomerToEdit({
        ...sageCustomer,
        routeID: sageURL.customer.routeID,
        customerID: sageURL.customer.customerID,
        sageCustomer: true,
      });
      setSageURL({ url: "", customer: null });
    }
  }, [sageCustomer]);
  // Trigger refetch after update and reset edit state
  const onUpdate = useCallback(() => {
    refetch();
    setCustomerToEdit(null);
  }, [refetch]);

  const handleCustomerSelection = (customerData) => {
    if (customerData.sageID) {
      //setSelectedCustomer(customer)
      setSageURL({
        url: `customers/getsagecustomer/${customerData.sageID}`,
        customer: customerData,
      });
    } else {
      const customer = {
        customerID: customerData.customerID,
        id: customerData.sageID,
        name: customerData.customerName,
        notes: "",
        routeID: customerData.routeID,
        main_address: {
          address_line_1: "",
          address_line_2: "",
          city: "",
          postal_code: customerData.customerEirCode,
          region: "",
        },
        main_contact_person: {
          mobile: customerData.customerNumber,
          email: customerData.customerEmail,
        },
      };
      setCustomerToEdit({ ...customer, sageCustomer: false });
    }
  };

  // Handle popup dismissal and refetch on successful delete
  /*   const handleShowPopup = useCallback(() => {
    setShowPopup(false);
    if (deleteResponse) refetch();
  }, [deleteResponse, refetch]); */

  return (
    <div className="form-page">
      <PageTitle title="Manage Customers" />

      <FormTab
        tabs={["New Customer", "Edit Customer"]}
        selected={setSelectedTab}
        defaults={0}
      />
      {selectedTab === "Edit Customer" ? (
        <>
          {(sageLoading || loading) && <Loader color={"black"} />}
          {!customerToEdit && !sageLoading && !loading ? (
            <div className="manage-list-container">
              <CustomerList
                customerData={data}
                setCustomerToEdit={handleCustomerSelection}
                setCustomerToDelete={(customer) =>
                  showConfirmation(
                    "Are you sure you want to delete " + customer.customerName,
                    () =>
                      deleteData(
                        customer.sageID ? customer.sageID : customer.customerID,
                        null,
                        customer.sageID && true
                      ),
                    "Delete"
                  )
                }
              />
            </div>
          ) : (
            customerToEdit && (
              <Customer
                customerToEdit={customerToEdit}
                triggerUpdate={onUpdate}
              />
            )
          )}
        </>
      ) : (
        <Customer customerToEdit={null} triggerUpdate={onUpdate} />
      )}
    </div>
  );
}

import ListGroup from "react-bootstrap/ListGroup";
import { useState, useEffect } from "react";
import { useFetch } from "../../db/dbHooks";
import Loader from "../loader/Loader";
import { useConfirmation } from "../floating/ConfirmationPopupContext";
import RefreshBT from "../buttons/RefreshBT";
const DispatchCustomersList = ({
  activeItem,
  setSelectedCustomer,
  updateView,
  setUpdateView,
  isLocked,
}) => {
  const { showConfirmation } = useConfirmation();

  const { data, loading, error, refetch } = useFetch(
    "orders/customersAndRoutes"
  );
  const [routes, setRoutes] = useState([]);

  //updateView
  useEffect(() => {
    if (updateView) {
      refetch();
      setUpdateView(false);
    }
  }, [updateView]);
  useEffect(() => {
    if (data) {
      let routeName = [];
      data.map((customer) => {
        routeName.push(customer.routeName);
      });
      routeName = routeName.filter(
        (item, index) => routeName.indexOf(item) === index
      );
      setRoutes(routeName);
    }
  }, [data]);

  return (
    <div className="text-center">
      {loading ? (
        loading && <Loader color={"black"} />
      ) : error ? (
        <RefreshBT onClick={refetch} errorMessage={error} />
      ) : (
        routes.map((routeName, index) => (
          <div key={index}>
            <h5>{routeName}</h5>
            {data.map(
              (customer, index) =>
                routeName === customer.routeName && (
                  <ListGroup
                    key={index}
                    onClick={() =>
                      isLocked
                        ? showConfirmation(
                            "Unsaved changes detected. Do you still want to procced?",
                            () => setSelectedCustomer(customer),
                            "Confirm"
                          )
                        : setSelectedCustomer(customer)
                    }
                  >
                    <ListGroup.Item
                      className="w-100"
                      active={
                        activeItem?.customer?.customerID === customer.customerID
                      }
                      style={{
                        backgroundColor:
                          customer.status === "Dispatched"
                            ? "#79f77b"
                            : customer.status === "Processing"
                            ? "#f7cd79"
                            : "",
                      }}
                    >
                      {customer.customerName}
                    </ListGroup.Item>
                  </ListGroup>
                )
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default DispatchCustomersList;

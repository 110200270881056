import packageJson from "../../../package.json"; // Adjust the path as needed

const About = () => {
  return (
    <div className="settings-sage-page">
      <h6 className="mt-auto">App Version: {packageJson.version}</h6>
    </div>
  );
};

export default About;

import FolatingBT from "../../components/buttons/FloatingBT";
import OutstandingOrders from "../../components/outstandingOrders/OutstandingOders";

import "./Home.css";

export default function Home() {
  return (
    <div className="page-main">
      <OutstandingOrders />
      <FolatingBT link={"/NewOrder"} />
    </div>
  );
}

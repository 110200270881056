import { useState, useEffect } from "react";

const CheckOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Event listener for when the internet connection is restored
  const handleOnline = () => {
    setIsOnline(true);
  };

  // Event listener for when the internet connection is lost
  const handleOffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    // Set up event listeners for online and offline events
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    !isOnline && (
      <div
        style={{
          position:"absolute",
          left:0,
      top:"7vh",
          width: "100%",
          backgroundColor: "#f03e56",
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        You are offline
      </div>
    )
  );
};

export default CheckOnlineStatus;

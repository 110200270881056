import ListGroup from "react-bootstrap/ListGroup";
import { useState, useEffect, memo, useRef, useCallback } from "react";
import "./CreateOrder.css";
import { useConfirmation } from "../floating/ConfirmationPopupContext";
import debounce from "lodash.debounce";

export default function OrderList({
  items,
  itemToRemove,
  productToAdd,
  handleItemChange,
  showProductList,
  previousProducts,
}) {
  const [orderItems, setOrderItems] = useState([]);
  const [showActionList, setShowActionList] = useState(-1);
  const { showConfirmation } = useConfirmation();
  const qtyRef = useRef({});
  const priceRef = useRef({});

  useEffect(() => {
    setOrderItems(items);
    setShowActionList(null);
  }, [items]);

  const handleQtyFocus = (id) => {
    if (qtyRef.current[id]) {
      qtyRef.current[id].select(); // Select the input for the specific item
    }
  };
  const handlePriceFocus = (id) => {
    if (priceRef.current[id]) {
      priceRef.current[id].select(); // Select the input for the specific item
    }
  };
  const handleItemOnChange = (product) => {
    const updatedItems = orderItems.map((orderItem) =>
      orderItem.productID === product.productID ? product : orderItem
    );
    setOrderItems(updatedItems);
  };

  return (
    <div className="order-list-container">
      <div className="order-list">
        <ListHeadings />
        {orderItems.length > 0 &&
          orderItems.map((item, index) => (
            <div key={item.productID}>
              <ListGroup horizontal>
                <ListGroup.Item
                  className="order_list_productName"
                  onClick={() =>
                    setShowActionList(index === showActionList ? null : index)
                  }
                >
                  {item.productName}
                </ListGroup.Item>

                <ListGroup.Item className="order_list_item_editable">
                  <input
                    ref={(el) => (qtyRef.current[item.productID] = el)} // Assign ref based on item ID
                    onFocus={() => handleQtyFocus(item.productID)}
                    type="number"
                    value={Number(item.quantity)}
                    onChange={(e) =>
                      handleItemOnChange({
                        ...item,
                        quantity: e.target.value,
                      })
                    }
                    onBlur={(e) =>
                      handleItemChange({ ...item, quantity: e.target.value })
                    }
                  />
                </ListGroup.Item>
                <ListGroup.Item className="order_list_item_editable">
                  <input
                    ref={(el) => (priceRef.current[item.productID] = el)} // Assign ref based on item ID
                    onFocus={() => handlePriceFocus(item.productID)}
                    type="number"
                    value={Number(item.price)}
                    onChange={(e) =>
                      handleItemOnChange({ ...item, price: e.target.value })
                    }
                    onBlur={(e) =>
                      handleItemChange({ ...item, price: e.target.value })
                    }
                  />
                </ListGroup.Item>
                <ListGroup.Item className="order_list_item_editable">
                  {parseFloat(
                    (Number(item.price) * Number(item.quantity)).toFixed(2)
                  )}
                </ListGroup.Item>
              </ListGroup>
              <ActionList
                trigger={showActionList === index}
                setTrigger={(e) => setShowActionList(e)}
                deleteItem={() =>
                  showConfirmation(
                    "Are you sure you want to remove " + item.productName,
                    () => itemToRemove(item),
                    "Remove"
                  )
                }
              />
            </div>
          ))}
        {previousProducts && (
          <PreviousProductsList
            previousProducts={previousProducts}
            clickedProduct={productToAdd}
          />
        )}{" "}
        <FillEmpty />
      </div>
      <button
        onClick={() => showProductList()}
        className="order_list_floating_bt"
      >
        +
      </button>
    </div>
  );
}

const ListHeadings = () => {
  return (
    <div className="list-header sticky">
      <ListGroup horizontal>
        <ListGroup.Item className="order_list_productName">
          Item Name
        </ListGroup.Item>
        <ListGroup.Item className="order_list_other_items">Qty</ListGroup.Item>
        <ListGroup.Item className="order_list_other_items">
          Price
        </ListGroup.Item>
        <ListGroup.Item className="order_list_other_items">
          Total
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

const ActionList = ({ trigger = false, setTrigger, deleteItem }) => {
  const handleClick = () => {
    setTrigger(null);
    deleteItem();
  };
  return (
    <ListGroup hidden={!trigger}>
      <ListGroup.Item
        className="bg-danger text-white text-center"
        onClick={() => handleClick()}
      >
        Delete Item
      </ListGroup.Item>
    </ListGroup>
  );
};

const PreviousProductsList = ({ previousProducts, clickedProduct }) => {
  return (
    previousProducts.length > 0 && (
      <>
        <ListGroup>
          <ListGroup.Item className="w-100 text-center previous-product">
            ****Previous Order****
          </ListGroup.Item>
        </ListGroup>
        {previousProducts.map((item) => (
          <ListGroup
            className="clickable_list_item"
            horizontal
            key={item.productID}
            onClick={() => clickedProduct(item)}
          >
            <ListGroup.Item className="order_list_productName previous-product">
              {item.productName}
            </ListGroup.Item>
            <ListGroup.Item className="order_list_other_items previous-product">
              {Number(item.quantity)}
            </ListGroup.Item>
            <ListGroup.Item className="order_list_other_items previous-product">
              {Number(item.price)}
            </ListGroup.Item>
            <ListGroup.Item className="order_list_other_items previous-product">
              {Number(item.price * item.quantity)}
            </ListGroup.Item>
          </ListGroup>
        ))}
      </>
    )
  );
};

const FillEmpty = memo(function FillEmpty() {
  const [emptyItems, setEmptyItems] = useState([
    -1, -2, -3, -4, -5, -6, -7, -8, -9,
  ]);

  return (
    <>
      {emptyItems.map((item) => (
        <ListGroup horizontal key={item + 1000}>
          <ListGroup.Item
            className="order_list_productName"
            style={{ color: "#ffffff00" }}
          ></ListGroup.Item>
          <ListGroup.Item
            className="order_list_other_items"
            style={{ color: "#ffffff00" }}
          >
            .
          </ListGroup.Item>
          <ListGroup.Item
            className="order_list_other_items"
            style={{ color: "#ffffff00" }}
          >
            .
          </ListGroup.Item>
          <ListGroup.Item
            className="order_list_other_items"
            style={{ color: "#ffffff00" }}
          >
            .
          </ListGroup.Item>
        </ListGroup>
      ))}
    </>
  );
});

import { useFetch, usePost, useUpdate, useWebSocket } from "../../db/dbHooks";
import { useState, useEffect, useContext } from "react";
import { usePopup } from "../../components/floating/PopupContext";
import { useConfirmation } from "../../components/floating/ConfirmationPopupContext";
import Loader from "../../components/loader/Loader";
import { AuthContext } from "../../fun/authContext";

const Sage = () => {
  const { sageSignout, sageSignin, user } = useContext(AuthContext);
  const [socketData, setSocketData] = useState({ url: "", type: "" });
  const [disaple, setDisaple] = useState(false);
  const { data, loading, error, sendData } = useWebSocket(
    socketData.url,
    socketData.type,
    null
  );
  const {
    data: sageSettings,
    loading: settingsLoading,
    error: sageSettingsError,
    refetch: settingsRefetch,
  } = useFetch(user.sageLoginState ? "sage/sageSettings" : "", true);
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("settings");

  const { showPopupSuccess, showPopupError } = usePopup();
  const { showConfirmation } = useConfirmation();
  useEffect(() => {
    if (data) {
      setSocketData({ url: "", type: "" });
    }
  }, [data, error]);

  useEffect(() => {
    if (updateResponse) {
      showPopupSuccess(updateResponse.message);
      settingsRefetch();
    } else if (updateError) {
      showPopupError(updateError);
    }
  }, [updateResponse, updateError]);

  const updateSetting = (e) => {
    updateData(null, e);
  };
  return settingsLoading ? (
    <Loader color={"black"} />
  ) : (
    <div className="settings-sage-page">
      {sageSettings ? (
        <>
          <div className="sage_title">Business</div>

          <SettingsSelect
            title={"Defaut business Account"}
            data={sageSettings?.businesses.$items}
            name={"default_business_account"}
            selected={sageSettings?.default_business_account}
            disabled={sageSettings.default_business_account}
            updateDatebase={updateSetting}
          />
          <SettingsList title={"Signed in as"} text={sageSettings.user} />
          {sageSettings.default_business_account ? (
            <>
              <div className="sage_title">Customers</div>
              <SettingsList
                title={"Import Customers"}
                btName={"Import"}
                onclick={() =>
                  showConfirmation(
                    "Do you want to import all customers to Sage?",
                    () =>
                      setSocketData({
                        url: "ImportCustomers",
                        type: "ImportCustomers",
                      }),
                    "Export"
                  )
                }
              />

              <SettingsList
                title={"Export Customers"}
                btName={"Export"}
                onclick={() =>
                  showConfirmation(
                    "Do you want to export all customers to Sage?",
                    () =>
                      setSocketData({
                        url: "ExportCustomers",
                        type: "ExportCustomers",
                      }),
                    "Export"
                  )
                }
              />
              <SettingsSelect
                title={"Defaut customer ledger account"}
                data={sageSettings?.$items}
                name={"customer_ledger_account"}
                selected={sageSettings?.customer_ledger_account}
                updateDatebase={updateSetting}
              />
              <div className="sage_title">Products</div>

              <SettingsList
                title={"Improt Products"}
                btName={"Improt"}
                onclick={() =>
                  showConfirmation(
                    "Do you want to imprort all products from Sage?",
                    () =>
                      setSocketData({
                        url: "ImportProducts",
                        type: "ImportProducts",
                      }),
                    "Import"
                  )
                }
              />
              <SettingsList
                title={"Export Products"}
                btName={"Export"}
                onclick={() =>
                  showConfirmation(
                    "Do you want to export all products to Sage?",
                    () =>
                      setSocketData({ url: "Sage", type: "exportProducts" }),
                    "Export"
                  )
                }
              />
              <SettingsList
                title={"Match Sage Customers"}
                btName={"Match Sage"}
                onclick={() =>
                  showConfirmation(
                    "Do you want to update all the products to match Sage?",
                    () =>
                      setSocketData({
                        url: "MatchSageCustomers",
                        type: "MatchSageCustomers",
                      }),
                    "Update"
                  )
                }
              />
              <SettingsSelect
                title={"Defaut Sale Account"}
                data={sageSettings?.$items}
                name={"product_sale_account"}
                selected={sageSettings?.product_sale_account}
                updateDatebase={updateSetting}
              />

              <SettingsSelect
                title={"Defaut Purchase Account"}
                data={sageSettings?.$items}
                name={"product_purchase_account"}
                selected={sageSettings?.product_purchase_account}
                updateDatebase={updateSetting}
              />
              <SettingsSelect
                title={"Defaut Product Price Type"}
                data={sageSettings?.priceType.$items}
                name={"product_sale_price_type"}
                selected={sageSettings?.product_sale_price_type}
                updateDatebase={updateSetting}
              />
            </>
          ) : (
            ""
          )}
          <button className="sage-logout my-auto" onClick={() => sageSignout()}>
            Unlink Sage
          </button>
        </>
      ) : (
        <button className="sage-login my-auto" onClick={() => sageSignin()}>
          Link Sage
        </button>
      )}
    </div>
  );
};

export default Sage;

const SettingsList = ({ title, btName, onclick, text = null }) => {
  return (
    <div
      style={{
        marginBottom: "10px",
        width: "100%",
      }}
    >
      <div
        style={{
          padding: "10px 20px",
          display: "flex",
          borderBottom: "1px solid #ddd",

          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {<h6 className="mb-0">{title}</h6>}
        {!text ? (
          <button
            onClick={onclick}
            style={{
              maxWidth: "200px",
              border: 0,
              borderRadius: "25px",
              backgroundColor: "black",
              color: "white",
              minHeight: "45px",
              minWidth: "100px",
            }}
          >
            {btName}
          </button>
        ) : (
          <p>{text}</p>
        )}
      </div>
    </div>
  );
};

const SettingsSelect = ({
  title,
  data,
  name,
  selected,
  updateDatebase,
  disabled = false,
}) => {
  const handleSelect = (e) => {
    updateDatebase({
      setting: { name: name, value: e },
    });
  };

  return (
    <div
      style={{
        marginBottom: "10px",
        width: "100%",
      }}
    >
      <div
        style={{
          padding: "10px 20px",
          display: "flex",
          borderBottom: "1px solid #ddd",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {<h6 className="mb-0">{title}</h6>}
        <select
          disabled={disabled}
          style={{
            maxWidth: "100%",
            width: "250px",
            borderRadius: "15px",
            borderColor: "gray",
          }}
          value={selected}
          onChange={(e) =>
            e.target.value !== "" && handleSelect(e.target.value)
          }
        >
          <option value={""}>Select an option</option>
          {data?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.displayed_as}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

import { useState, useEffect, useContext, useRef } from "react";
import { useCookies } from "react-cookie";
import { usePopup } from "../components/floating/PopupContext";
import { useLoadingSpinner } from "../components/floating/LoadingSpinnerContext";
import { AuthContext } from "../fun/authContext";
const wsLink = process.env.REACT_APP_WS_API_URL;
const apiLink = process.env.REACT_APP_API_URL;
//delete request
export const useDelete = (url) => {
  const [deleteResponse, setDeleteResponse] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const { getSageAccessToken, getAccessToken } = useContext(AuthContext);

  const deleteData = async (id, bodyData, sageRequest) => {
    setDeleteLoading(true);
    setDeleteError(null);
    setDeleteResponse(null);
    try {
      const authorization = await getAccessToken();
      const stoken = sageRequest ? await getSageAccessToken() : "";

      const res = await fetch(`${apiLink + url}/${id}`, {
        method: "DELETE",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          authorization,
          stoken,
        },
        body: JSON.stringify({ ...bodyData }),
      });
      if (!res.ok) {
        const jsonResponse = await res.json();
        throw jsonResponse.message
          ? jsonResponse.message
          : "Network response was not ok";
      }
      const result = await res.json();
      setDeleteResponse(result);
    } catch (err) {
      typeof err !== "object"
        ? setDeleteError(err)
        : setDeleteError("Failed to connect to the serve");
    } finally {
      setDeleteLoading(false);
    }
  };

  return { deleteResponse, deleteLoading, deleteError, deleteData };
};

//get request
export const useFetch = (url, sageRequest) => {
  const { getSageAccessToken, getAccessToken } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    setData(null);

    try {
      const authorization = await getAccessToken();
      const stoken = sageRequest ? await getSageAccessToken() : "";

      const response = await fetch(apiLink + url, {
        credentials: "omit",
        headers: {
          authorization,
          stoken,
        },
      });
      const data = await response.json();

      if (!response.ok) {
        throw data.message ? data.message : "Network response was not ok";
      }

      setData(data);
    } catch (err) {
      typeof err !== "object"
        ? setError(err)
        : setError("Failed to connect to the serve");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (url !== "") fetchData();
  }, [url]);

  return { data, loading, error, refetch: fetchData };
};

//post request
export const usePost = (url) => {
  const { getSageAccessToken, getAccessToken } = useContext(AuthContext);
  const [postResponse, setPostResponse] = useState(null);
  const [postLoading, setPostLoading] = useState(false);
  const [postError, setPostError] = useState(null);

  const postData = async (data, sageRequest) => {
    setPostLoading(true);
    setPostError(null);
    setPostResponse(null);
    try {
      const authorization = url !== "auth/login" ? await getAccessToken() : "";
      const stoken = sageRequest ? await getSageAccessToken() : "";
      const res = await fetch(apiLink + url, {
        method: "POST",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          authorization,
          stoken,
        },

        body: JSON.stringify({ ...data }),
      });
      const jsonResponse = await res.json();

      if (!res.ok) {
        throw jsonResponse
          ? jsonResponse.message
          : "Network response was not ok";
      }

      setPostResponse(jsonResponse);
    } catch (err) {
      typeof err !== "object"
        ? setPostError(err)
        : setPostError("Failed to connect to the serve");
    } finally {
      setPostLoading(false);
    }
  };

  return { postResponse, postLoading, postError, postData };
};

//update request
export const useUpdate = (url) => {
  const { getSageAccessToken, getAccessToken } = useContext(AuthContext);
  const [updateResponse, setResponse] = useState(null);
  const [updateLoading, setupdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const updateData = async (id, data, sageRequest) => {
    setupdateLoading(true);
    setUpdateError(null);
    setResponse(null);

    try {
      const authorization = await getAccessToken();
      const stoken = sageRequest ? await getSageAccessToken() : "";

      const res = await fetch(`${apiLink + url}/${id}`, {
        method: "PUT",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          authorization,
          stoken,
        },
        body: JSON.stringify({ ...data }),
      });
      if (!res.ok) {
        const jsonResponse = await res.json();
        throw jsonResponse
          ? jsonResponse.message
          : "Network response was not ok";
      }
      const result = await res.json();

      setResponse(result);
    } catch (err) {
      typeof err !== "object"
        ? setUpdateError(err)
        : setUpdateError("Failed to connect to the serve");
    } finally {
      setupdateLoading(false);
    }
  };

  return { updateResponse, updateLoading, updateError, updateData };
};

export const useWebSocket = (url, type = "", dataToSend = null) => {
  const { getSageAccessToken, getAccessToken } = useContext(AuthContext);
  const { showPopupSuccess, showPopupError } = usePopup();
  const { showLoadingSpinner, hideLoadingSpinner, setLoadingSpinnerMessage } =
    useLoadingSpinner();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const ws = useRef(null); // Ref to hold the WebSocket instance

  const initializeWebSocket = async () => {
    setLoading(true);
    setError(null);
    setData(null);
    showLoadingSpinner();

    try {
      const authorization = await getAccessToken();
      const stoken = await getSageAccessToken();
      // Initialize WebSocket connection
      ws.current = new WebSocket(
        `${wsLink + url}?authorization=${authorization}&SToken=${stoken}` // Adjust URL & protocol
      );

      // Send initial headers or tokens when WebSocket opens
      ws.current.onopen = () => {
        type !== "" && sendData(type, dataToSend);
      };

      // Listen for messages from the server
      ws.current.onmessage = (event) => {
        try {
          const receivedData = JSON.parse(event.data);

          setLoadingSpinnerMessage(receivedData.type, receivedData.message);
        } catch (err) {
          setError("Failed to parse server message");
        }
      };

      // Handle WebSocket errors
      ws.current.onerror = (err) => {
        setError("WebSocket error occurred");
      };

      // Handle WebSocket closure
      ws.current.onclose = () => {
        setData(true);
        setLoading(false);
        hideLoadingSpinner();
      };
    } catch (err) {
      showPopupError("Failed to initialize WebSocket");

      showPopupError("Failed to initialize WebSocket");
      setLoading(false);
      setData(true);
      hideLoadingSpinner();
    }
  };

  // Send data to the WebSocket server
  const sendData = (type = "", data = null) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify({ type, ...data }));
    } else {
      showPopupError("WebSocket is not open. Unable to send data.");
    }
  };

  useEffect(() => {
    if (url !== "") {
      initializeWebSocket();
    }

    // Cleanup WebSocket when component unmounts
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return { data, loading, error, sendData };
};

/* const ErrorHandling = async (response) => {

  switch (response.message) {
    case "Unauthorized. Please login":
      setIsAuth(false);
      break;

    default:
      break;
  }
  if (data.redirectTo) {
    window.location.href = data.redirectTo; // Client-side redirect
  }
}; */

import ListGroup from "react-bootstrap/ListGroup";
import { useState } from "react";
import "./List.css";
import Filter from "../filter/Filter";

export default function CategoryList({
  categoryData,
  updateData,
  setcategoryToDelete,
  setcategoryToEdit,
}) {
  const [search, setSearch] = useState("");
  const [clickedItem, setClickedItem] = useState(-1);

  return (
    <div className="list-container">
      <div className="sticky">
        <Filter setSearch={setSearch} />
        <ListGroup horizontal className="list-header">
          <ListGroup.Item className="item1 w-100">Category Name</ListGroup.Item>
        </ListGroup>
      </div>
      <div className="list-body">
        {categoryData &&
          categoryData.map(
            (category, index) =>
              (category.categoryName.search(new RegExp(search, "i")) !== -1 ||
                search === "") && (
                <ListGroup
                  key={category.categoryID}
                  className="clickable_list_item"
                >
                  <ListGroup.Item
                    className="item1 w-100"
                    onClick={() =>
                      setClickedItem(clickedItem === index ? -1 : index)
                    }
                  >
                    {category.categoryName}
                  </ListGroup.Item>
                  {clickedItem === index && (
                    <div>
                      <ListGroup.Item
                        className="modify-list-item"
                        onClick={() => setcategoryToEdit(category)}
                      >
                        {"Modify " + category.categoryName}
                      </ListGroup.Item>
                      <ListGroup.Item
                        className="delete-list-item"
                        onClick={() => setcategoryToDelete(category)}
                      >
                        {"Delete " + category.categoryName}
                      </ListGroup.Item>
                    </div>
                  )}
                </ListGroup>
              )
          )}
      </div>
    </div>
  );
}

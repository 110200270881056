import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { usePost, useUpdate } from "../../db/dbHooks";
import SubmitBT from "../buttons/SubmitBT";
import Row from "react-bootstrap/Row";
import { usePopup } from "../floating/PopupContext";

export default function Category({ categoryToEdit = null, reFetch }) {
  const { showPopupSuccess, showPopupError } = usePopup();
  const [validated, setValidated] = useState(false);
  const emptyCategory = {
    categoryID: null,
    categoryName: "",
    categoryCode: "",
  };
  const [category, setCategory] = useState(
    categoryToEdit ? categoryToEdit : emptyCategory
  );
  const { postResponse, postLoading, postError, postData } =
    usePost("categories");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("categories");

  useEffect(() => {
    if (!categoryToEdit) {
      resetState();
    }
  }, [categoryToEdit]);

  useEffect(() => {
    if (updateError) {
      showPopupError(updateError);
    } else if (updateResponse) {
      showPopupSuccess(updateResponse.message);
      reFetch();
      resetState();
    }
  }, [updateResponse, updateError]);

  useEffect(() => {
    if (postError) {
      showPopupError(postError);
    } else if (postResponse) {
      showPopupSuccess(postResponse.message);
      reFetch();
      resetState();
    }
  }, [postResponse, postError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (categoryToEdit) {
        updateData(category.categoryID, { category });
      } else {
        postData({ category });
      }
    }

    setValidated(true);
  };

  const resetState = () => {
    setValidated(false);
    setCategory(emptyCategory);
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="form"
    >
      <Row>
        <Form.Group as={Col} className="form_field">
          <Form.Label>Category Code</Form.Label>
          <Form.Control
            type="text"
            required
            placeholder="Example L for Lamb"
            id="categoryCode"
            autoComplete="off"
            maxLength={3}
            value={category.categoryCode}
            onChange={(e) =>
              setCategory({
                ...category,
                categoryCode: e.target.value.toUpperCase(),
              })
            }
          />
        </Form.Group>

        <Form.Group as={Col} className="form_field">
          <Form.Label>Category Name</Form.Label>
          <Form.Control
            type="text"
            required
            placeholder="Enter category name"
            id="categoryName"
            autoComplete="off"
            value={category.categoryName}
            onChange={(e) =>
              setCategory({ ...category, categoryName: e.target.value })
            }
          />
        </Form.Group>
      </Row>

      <SubmitBT
        BTname={categoryToEdit ? "Update Category" : "Add Category"}
        loading={postLoading || updateLoading}
      />
    </Form>
  );
}

import { useState, useEffect, useContext } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useFetch, useWebSocket } from "../../db/dbHooks";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import SubmitBT from "../../components/buttons/SubmitBT";
import { AuthContext } from "../../fun/authContext";
export default function PostOrders() {
  const { user } = useContext(AuthContext);
  const [validated, setValidated] = useState(false);
  const [url, setUrl] = useState("");
  const [inputData, setInputData] = useState({
    date: new Date().toISOString().split("T")[0],
    routeName: null,
    routeID: null,
  });
  const { data, loading, error, sendData } = useWebSocket(
    url,
    "PostOrdersToSage",
    inputData
  );
  const { data: routes, error: routesError } = useFetch("routes");
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setUrl("PostOrdersToSage");
    }
    setValidated(true);
  };

  useEffect(() => {
    data && setUrl("");
  }, [data]);

  const handleDropdownSelect = (e) => {
    routes.map(({ routeID, routeName }) => {
      routeID === Number(e.target.value) &&
        setInputData({ ...inputData, routeName, routeID });
    });
  };
  return (
    <div>
      <PageTitle title={"Post Orders To Sage"} />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="form"
        >
          <Form.Group as={Col}>
            <Form.Label>Route</Form.Label>
            <Form.Select
              required
              isInvalid={validated && inputData.routeID === ""}
              aria-label="Default select example"
              onChange={handleDropdownSelect}
            >
              <option value={""}>Select Route</option>
              {routes?.map((route) => (
                <option key={route.routeID} value={route.routeID}>
                  {route.routeName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group>
            <Form.Label>Delivery Date</Form.Label>
            <Form.Control
              type="date"
              required
              value={inputData.date}
              onChange={(e) =>
                setInputData({ ...inputData, date: e.target.value })
              }
            />
          </Form.Group>
          <SubmitBT BTname={"Post Orders"} disabled={!user.sageLoginState} />
        </Form>
      </div>
    </div>
  );
}

import Register from "../../components/forms/Register ";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useContext } from "react";
import { AuthContext } from "../../fun/authContext";

export default function Profile() {
  const { user } = useContext(AuthContext);
  return (
    <div className="form-page">
      <PageTitle title={"My Profile"} />
      <Register userToEdit={user} viewOnly={true} />
    </div>
  );
}

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import SubmitBT from "../buttons/SubmitBT";
import { useFetch } from "../../db/dbHooks";
import Popup from "../floating/Popup";

export default function DispatchSheetForm({ resultsLoading, onSubmit }) {
  const [validated, setValidated] = useState(false);
  const { data, loading, error, refetch } = useFetch("routes");
  const [inputData, setInputData] = useState({
    date: null,
    showPrice: false,
    routeName: null,
    routeID: null,
    driver: "",
    vanReg: "",
    date: new Date().toISOString().split("T")[0],
  });

  const [popup, setPopup] = useState({
    trigger: false,
    error: null,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      onSubmit(inputData);
    }
    setValidated(true);
  };

  const handleDropdownSelect = (e) => {
    data.map(({ routeID, routeName }) => {
      routeID === Number(e.target.value) &&
        setInputData({ ...inputData, routeName, routeID });
    });
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="form"
    >
      <Form.Group as={Col}>
        <Form.Label>Route</Form.Label>
        <Form.Select
          required
          isInvalid={validated && inputData.routeID === ""}
          aria-label="Default select example"
          onChange={handleDropdownSelect}
        >
          <option value={""}>Select Route</option>
          {data &&
            data.map((route) => (
              <option key={route.routeID} value={route.routeID}>
                {route.routeName}
              </option>
            ))}
        </Form.Select>
      </Form.Group>

      <Form.Group>
        <Form.Label>Delivery Date</Form.Label>
        <Form.Control
          type="date"
          required
          value={inputData.date}
          onChange={(e) => setInputData({ ...inputData, date: e.target.value })}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Driver</Form.Label>
        <Form.Control
          id="driver"
          type="text"
          required
          placeholder="Enter Driver Name"
          value={inputData.driver}
          onChange={(e) =>
            setInputData({ ...inputData, driver: e.target.value })
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Van Reg</Form.Label>
        <Form.Control
          id="VanReg"
          type="text"
          required
          placeholder="Enter Van Reg No"
          value={inputData.vanReg}
          onChange={(e) =>
            setInputData({ ...inputData, vanReg: e.target.value })
          }
        />
      </Form.Group>

      <Form.Group>
        <Form.Check // prettier-ignore
          type={"checkbox"}
          label={"Show prices"}
          value={inputData.showPrice}
          onChange={() =>
            setInputData({ ...inputData, showPrice: !inputData.showPrice })
          }
        />
      </Form.Group>

      <SubmitBT BTname={"Generate Dispatch"} loading={resultsLoading} />
      {/*       <SubmitBT
        BTname={"Post To Sage"}
        style={{ backgroundColor: "green" }}
      /> */}

      {popup.trigger && (
        <Popup
          setTrigger={() => setPopup({ trigger: false })}
          error={popup.error}
        />
      )}
    </Form>
  );
}

import { useFetch } from "../../db/dbHooks";
import RefreshBT from "../buttons/RefreshBT";
import CustomerList from "../list/CustomerList";
import Loader from "../loader/Loader";

const SelectCustomer = ({ setSelectedCustomer, setShow }) => {
  const {
    data: customers,
    loading: customersLoading,
    error: customersError,
    refetch: customersRefetch,
  } = useFetch("customers/CustomersForOrders");

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="fixed-bg" onClick={() => setShow(false)}>
      <div className="fixed-inner" onClick={preventBubbling}>
        {!customersError ? (
          !customersLoading ? (
            <CustomerList
              customerData={customers}
              disableListMenu={true}
              setCustomerToEdit={setSelectedCustomer}
            />
          ) : (
            <Loader color={"black"} />
          )
        ) : (
          <>
            {" "}
            <p>{customersError}</p>
            <RefreshBT loading={customersLoading} onClick={customersRefetch} />
          </>
        )}
      </div>
    </div>
  );
};

export default SelectCustomer;

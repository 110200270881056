import FormTab from "../../components/forms/FormTab.js";
import { useState, useEffect } from "react";
import Register from "../../components/forms/Register .js";
import { useFetch, useDelete } from "../../db/dbHooks.js";
import UserList from "../../components/list/UsersList.js";
import "./Settings.css";

export default function ManageUsers() {
  const { data, loading, error, refetch } = useFetch("users");
  const { deleteResponse, deleteLoading, deleteError, deleteData } =
    useDelete("users");
  const [selectedTab, setSelectedTab] = useState("New User");
  const [userToEdit, setUserToEdit] = useState(null);

  useEffect(() => {
    setUserToEdit(null);
  }, [selectedTab]);

  const update = () => {
    refetch();
    setUserToEdit(null);
  };

  return (
    <div className="settings-right-side-content">
      <FormTab
        tabs={["New User", "Edit User"]}
        selected={setSelectedTab}
        defaults={0}
      />

      {selectedTab === "New User" ? (
        <Register reFetch={update} userToEdit={null} />
      ) : selectedTab === "Edit User" && userToEdit === null ? (
        <UserList userData={data} setUserToEdit={setUserToEdit} />
      ) : userToEdit ? (
        <Register userToEdit={userToEdit} reFetch={update} />
      ) : (
        ""
      )}
    </div>
  );
}

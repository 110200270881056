import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useContext, useEffect, useState } from "react";
import { useFetch, usePost, useUpdate } from "../../db/dbHooks";
import SubmitBT from "../buttons/SubmitBT";
import DropdownTab from "../dropdown/DropdownTab";
import { Link } from "react-router-dom";
import { usePopup } from "../floating/PopupContext";
import { AuthContext } from "../../fun/authContext";

export default function Customer({ customerToEdit = null, triggerUpdate }) {
  const { user } = useContext(AuthContext);

  const [validated, setValidated] = useState(false);
  const { showPopupSuccess, showPopupError } = usePopup();
  const [sageRequest, setSageRequest] = useState(
    true
    /*  ? customerToEdit
        ? customerToEdit.sageCustomer
          ? true
          : false
        : true
      : false */
  );

  const counties = [
    "Antrim",
    "Armagh",
    "Cavan",
    "Derry",
    "Donegal",
    "Down",
    "Fermanagh",
    "Monaghan",
    "Tyrone",
    "Cork",
    "Kerry",
    "Clare",
    "Limerick",
    "Tipperary",
    "Waterford",
    "Carlow",
    "Dublin",
    "Kildare",
    "Kilkenny",
    "Laois",
    "Longford",
    "Louth",
    "Meath",
    "Offaly",
    "Westmeath",
    "Wexford",
    "Wicklow",
    "Sligo",
    "Mayo",
    "Galway",
    "Leitrim",
    "Roscommon",
  ];
  const requestData = {
    id: customerToEdit?.id || null,
    customerID: customerToEdit?.customerID || null,
    name: customerToEdit?.name || "",
    contact_type_ids: ["CUSTOMER"],
    reference: customerToEdit?.reference || "",
    default_sales_ledger_account_id: null,
    notes: customerToEdit?.notes || "",
    routeID: customerToEdit?.routeID || null,
    main_address: {
      name: customerToEdit?.main_address.name || "Main",
      address_line_1: customerToEdit?.main_address.address_line_1 || "",
      address_line_2: customerToEdit?.main_address.address_line_2 || "",
      city: customerToEdit?.main_address.city || "",
      postal_code: customerToEdit?.main_address.postal_code || "",
      region: customerToEdit?.main_address.region || "",
      is_main_address: true,
    },

    main_contact_person: {
      mobile: customerToEdit?.main_contact_person.mobile || "",
      email: customerToEdit?.main_contact_person.email || "",
      is_main_contact: true,
      is_preferred_contact: true,
    },
  };
  const [customer, setCustomer] = useState(requestData);
  const { data: routes, error: routesError } = useFetch("routes");
  const { postResponse, postLoading, postError, postData } =
    usePost("customers");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("customers");

  //handle update data
  useEffect(() => {
    if (updateError) {
      showPopupError(updateError);
    } else if (updateResponse) {
      resetState(true);
      showPopupSuccess(updateResponse.message);
    }
  }, [updateResponse, updateError]);

  //handle post data
  useEffect(() => {
    if (postError) {
      showPopupError(postError);
    } else if (postResponse) {
      resetState(true);
      showPopupSuccess(postResponse.message);
    }
  }, [postResponse, postError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (customerToEdit) {
        if (JSON.stringify(requestData) !== JSON.stringify(customer)) {
          updateData(
            customer.id ? customer.id : customer.customerID,
            {
              customer,
            },
            sageRequest
          );
        } else {
          showPopupError("No Changes Were Made");
        }
      } else {
        postData({ customer }, sageRequest);
      }
    }
    setValidated(true);
  };

  const resetState = (updateData) => {
    setValidated(false);
    setCustomer(requestData);
    updateData && triggerUpdate();
  };
  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="form"
    >
      <Form.Check // prettier-ignore
        style={{ marginLeft: "auto", color: "green" }}
        disabled={true}
        checked={true}
        onChange={() => setSageRequest(!sageRequest)}
        type="switch"
        id="custom-switch"
        label={customerToEdit ? "Update Sage" : "Add To Sage"}
        reverse
      />

      <DropdownTab title={"Customer Details"} open={true}>
        <Row>
          <Form.Group as={Col} style={{ minWidth: "250px" }}>
            <Form.Label>Customer Name *</Form.Label>
            <Form.Control
              id="customerName"
              required
              type="text"
              placeholder="Enter customer name"
              value={customer.name}
              onChange={(e) =>
                setCustomer({ ...customer, name: e.target.value })
              }
            />
          </Form.Group>{" "}
          <Form.Group as={Col} style={{ minWidth: "250px" }}>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              id="customerEmailAddress"
              placeholder="example@ext.com"
              type="email"
              value={customer.main_contact_person.email || ""}
              required={sageRequest}
              onChange={(e) =>
                setCustomer({
                  ...customer,
                  main_contact_person: {
                    ...customer.main_contact_person,
                    email: e.target.value,
                  },
                })
              }
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} style={{ minWidth: "200px" }}>
            <Form.Label>Reference</Form.Label>
            <Form.Control
              id="reference"
              maxLength={10}
              disabled
              type="text"
              placeholder="Customer reference"
              value={customer.reference || ""}
              onChange={(e) =>
                setCustomer({ ...customer, reference: e.target.value })
              }
            />
          </Form.Group>{" "}
          <Form.Group as={Col} style={{ minWidth: "200px" }}>
            <Form.Label>Phone Number *</Form.Label>
            <Form.Control
              id="customerNumber"
              required
              placeholder="Enter phone number"
              type="number"
              value={customer.main_contact_person.mobile || ""}
              onChange={(e) =>
                setCustomer({
                  ...customer,
                  main_contact_person: {
                    ...customer.main_contact_person,
                    mobile: e.target.value,
                  },
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col} style={{ minWidth: "200px" }}>
            <Form.Label>Asign to a route</Form.Label>
            <Form.Select
              id="customerCounty"
              value={customer.routeID || ""}
              onChange={(e) =>
                setCustomer({ ...customer, routeID: e.target.value })
              }
            >
              <option value={""}>Don't asign</option>
              {routes &&
                routes.map((route) => (
                  <option key={route.routeID} value={route.routeID}>
                    {route.routeName}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Row>
      </DropdownTab>
      <DropdownTab title={"Address"}>
        <h6>Billing Address</h6>
        <Row>
          <Form.Group as={Col} style={{ minWidth: "250px" }}>
            <Form.Label>Address 1</Form.Label>
            <Form.Control
              placeholder="Address 1"
              id="adderssAndCity"
              required={sageRequest}
              value={customer.main_address.address_line_1 || ""}
              onChange={(e) =>
                setCustomer({
                  ...customer,
                  main_address: {
                    ...customer.main_address,
                    address_line_1: e.target.value,
                  },
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col} style={{ minWidth: "250px" }}>
            <Form.Label>Address 2</Form.Label>
            <Form.Control
              placeholder="Address 2"
              id="adderssAndCity"
              value={customer.main_address.address_line_2 || ""}
              onChange={(e) =>
                setCustomer({
                  ...customer,
                  main_address: {
                    ...customer.main_address,
                    address_line_2: e.target.value,
                  },
                })
              }
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} style={{ minWidth: "200px" }}>
            <Form.Label>City</Form.Label>
            <Form.Control
              placeholder="City"
              id="adderssAndCity"
              required={sageRequest}
              value={customer.main_address.city || ""}
              onChange={(e) =>
                setCustomer({
                  ...customer,
                  main_address: {
                    ...customer.main_address,
                    city: e.target.value,
                  },
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col} style={{ minWidth: "200px" }}>
            <Form.Label>County</Form.Label>
            <Form.Select
              id="customerCounty"
              value={customer.main_address.region || ""}
              required={sageRequest}
              isInvalid={validated && customer.main_address.region === ""}
              onChange={(e) =>
                setCustomer({
                  ...customer,
                  main_address: {
                    ...customer.main_address,
                    region: e.target.value,
                  },
                })
              }
            >
              <option value={""}>Choose County</option>
              {counties.map((county) => (
                <option key={county} value={county}>
                  {county}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} style={{ minWidth: "200px" }}>
            <Form.Label>Eircode</Form.Label>
            <Form.Control
              id="customerZipcode"
              placeholder="F35 ER65"
              required
              type="text"
              value={customer.main_address.postal_code || ""}
              onChange={(e) =>
                setCustomer({
                  ...customer,
                  main_address: {
                    ...customer.main_address,
                    postal_code: e.target.value,
                  },
                })
              }
            />
          </Form.Group>
        </Row>
      </DropdownTab>
      <DropdownTab title={"Additional Info"}>
        <Form.Group as={Col}>
          <Form.Label>Ledger account</Form.Label>
          <Form.Select disabled>
            <option value={""}>Sales - Products (4000)</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Note</Form.Label>
          <Form.Control
            as="textarea"
            value={customer.notes || ""}
            onChange={(e) =>
              setCustomer({ ...customer, notes: e.target.value })
            }
          />
        </Form.Group>{" "}
      </DropdownTab>

      <SubmitBT
        loading={updateLoading || postLoading}
        BTname={customerToEdit ? "Update Customer" : "Add Customer"}
      />
    </Form>
  );
}

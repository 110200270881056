import { useEffect, useState } from "react";
import "./SidePanel.css";
import { Justify } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SidePanel = ({ items }) => {
  const [showPanel, setShowPanel] = useState(true);
  const [page, setPage] = useState(window.location.pathname);
  const location = useLocation(); // This hook provides the current location object

  useEffect(() => {
    setPage(location.pathname);
  }, [location]);

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      className={
        !showPanel
          ? "panel-container panel-container-closed"
          : "panel-container"
      }
      onClick={() => setShowPanel(!showPanel)}
    >
      <button
        className={showPanel ? "hidden" : ""}
        onClick={() => setShowPanel(!showPanel)}
        style={{ border: 0 }}
      >
        <Justify size={30} />
      </button>
      <nav
        className={"panel-body " + (!showPanel ? "hidden" : "")}
        onClick={preventBubbling}
      >
        {items.map((arrayElements, index) => (
          <div className="list-group" key={index}>
            {arrayElements.map((item) => (
              <Link
                key={item.name}
                className={
                  "list-item prevent-select " +
                  (page.includes("/Settings/" + item.name) && "active")
                }
                to={item.name}
                onClick={() => {
                  setShowPanel(false);
                }}
              >
                <div>
                  <item.icon color={"gray"} size={20} />
                </div>
                <div>
                  <p style={{ marginBottom: 0, fontWeight: 400 }}>
                    {item.name}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default SidePanel;

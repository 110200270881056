import CreateOrder from "../../components/createOder/CreateOrder";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useState, useEffect } from "react";
import { useFetch, usePost, useUpdate } from "../../db/dbHooks";
import { useLocation } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { usePopup } from "../../components/floating/PopupContext";

export default function NewOrder() {
  const location = useLocation();
  const { showPopupSuccess, showPopupError } = usePopup();
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("orders/updateOrder");
  const { postResponse, postLoading, postError, postData } = usePost("orders");
  const {
    data: products,
    loading: productsLoading,
    error: productsError,
    refetch: productsRefetch,
  } = useFetch("products/productsForOrder");
  const [isLocked, setIsLocked] = useState(false);

  // if order ID available get order
  const {
    data: existingOrder,
    loading: existingOrderLoading,
    error: existingOrderError,
  } = useFetch(
    location.state?.createOrder?.orderID
      ? `orders/orderwithdetails/${location.state.createOrder.orderID}`
      : ""
  );
  const orderHeaderDefault = {
    orderID: null,
    customerID: null,
    customerName: null,
    routeName: null,
    deliveryDate: null,
    routeID: null,
    days: null,
    note: null,
    total: 0,
    ...location.state?.createOrder,
  };

  // Post request handling
  useEffect(() => {
    if (updateResponse) {
      showPopupSuccess(updateResponse.message);
    } else if (updateError) {
      showPopupError(updateError);
    }
  }, [updateResponse, updateError]);

  // update request handling
  useEffect(() => {
    if (postResponse) {
      showPopupSuccess(postResponse.message);
    } else if (postError) {
      showPopupError(postError);
    }
  }, [postResponse, postError]);

  // update request handling
  useEffect(() => {
    if (existingOrder) {
      if (existingOrder.order.status === "Delivery") {
        showPopupError("Order cannot be modified");
        setIsLocked(true);
      } else {
        setIsLocked(false);
      }
    }
  }, [existingOrder]);

  //handle submit
  const handleSubmit = (data) => {
    if (data.order.orderID) {
      updateData(data.order.orderID, data);
    } else {
      postData(data);
    }
  };

  return (
    <div className="page-main">
      <PageTitle title={existingOrder ? "Update Order" : "New Order"} />
      {productsLoading || existingOrderLoading ? (
        <Loader color={"black"} />
      ) : !isLocked ? (
        <CreateOrder
          loading={postLoading || updateLoading}
          existingOrderHeader={
            existingOrder
              ? { ...existingOrder.order, ...location.state.createOrder }
              : orderHeaderDefault
          }
          resetStates={postResponse || updateResponse}
          orderItem={existingOrder ? existingOrder.orderDetails : []}
          products={products?.products}
          categories={products?.categories}
          onSubmit={(e) => handleSubmit(e)}
        />
      ) : (
        <p>Order cannot be modified. Order in delivery state</p>
      )}
    </div>
  );
}

import "./Floating.css";
import { Check2Circle, XCircle } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";

export const ConfirmationPopup = ({
  Icon = null,
  message = "",
  btName = "Confirm",
  onConfirm,
  onClose,
}) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <div className="fixed-bg">
      <div className="ConfirmationPopup-inner">
        <div
          className="d-flex align-items-center"
          style={{ marginBottom: "15px" }}
        >
          {Icon && (
            <Icon color="Red" fontSize={40} style={{ marginRight: "5px" }} />
          )}{" "}
          <p style={{ fontWeight: 600, marginLeft: "10px", marginBottom: 0 }}>
            {message}
          </p>
        </div>
        <div className="Confirmation_bt_container">
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>{" "}
          <Button variant="success" onClick={handleConfirm}>
            {btName ? btName : "Confirm"}
          </Button>
        </div>
      </div>
    </div>
  );
};

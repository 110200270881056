import SubmitBT from "../../components/buttons/SubmitBT";
import { useState } from "react";
import { getFromLocalDB, storeInLocalDB } from "../../fun/fun";

const BatchEditor = ({ productData, onSubmit }) => {
  const lastBatch = getFromLocalDB("productID:" + productData.productID);
  const [modifiedProduct, setModifiedProduct] = useState(productData);

  const preventBubbling = (event) => {
    event.stopPropagation();
  };
  const handleSubmit = () => {
    if (
      modifiedProduct.editedBatch &&
      productData.batch !== modifiedProduct.batch
    ) {
      storeInLocalDB(
        "productID:" + productData.productID,
        modifiedProduct.batch
      );
      onSubmit(modifiedProduct);
    } else onSubmit(null);
  };
  return (
    <div className="fixed-bg" onClick={() => onSubmit(null)}>
      <div
        onClick={preventBubbling}
        style={{
          zIndex: "203",
          background: "white",
          maxWidth: "500px",
          maxHeight: "40vh",
          padding: "10px",
          overflowY: "auto",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "15px",
        }}
      >
        <h4>{productData.productName + " " + "Batch"}</h4>
        <button
          style={{
            borderRadius: 20,
            borderColor: "#dee2e6",
            borderStyle: "solid",
            backgroundColor: "white",
            marginBottom: "4px",
          }}
          onClick={(e) =>
            setModifiedProduct({
              ...productData,
              batch: lastBatch,
              editedBatch: true,
            })
          }
        >
          {lastBatch}
        </button>
        <input
          className="text-center"
          type="text"
          autoFocus
          value={modifiedProduct.batch ? modifiedProduct.batch : ""}
          onChange={(e) =>
            setModifiedProduct({
              ...productData,
              batch: e.target.value,
              editedBatch: true,
            })
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSubmit();
          }}
        />
        <SubmitBT BTname="Set Batch" onClick={() => handleSubmit()} />
      </div>
    </div>
  );
};

export default BatchEditor;

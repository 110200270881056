import { PlusLg, DashLg } from "react-bootstrap-icons";
import { useRef, useState, useEffect } from "react";
import FormTab from "../forms/FormTab";
import ListGroup from "react-bootstrap/ListGroup";
import SubmitBT from "../buttons/SubmitBT";

export default function QtyEditor({ product, onSubmit }) {
  const [selectedTab, setSelectedTab] = useState("Single");
  const [dispatchQTY, setQty] = useState(
    fixNumber(product.dispatchQTY || product.quantity || 0)
  );
  return (
    <div className={"fixed-bg"}>
      <div
        className={"fixed-inner"}
        style={{ height: "fit-content" }}
        onClick={preventBubbling}
      >
        <div className="d-flex flex-column w-100 text-center ">
          <h4 style={{ textAlign: "center" }}>{product.productName}</h4>

          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <FormTab
              tabs={["Single", "Multible"]}
              selected={setSelectedTab}
              defaults={0}
            />
          </div>
          {selectedTab === "Single" ? (
            <>
              <SingleItemQty qty={dispatchQTY} setQty={setQty} />
              <div className="PB-range-slider-div">
                <input
                  type="range"
                  max={100}
                  min={0}
                  value={Number(dispatchQTY)}
                  onChange={(e) => setQty(fixNumber(e.target.value))}
                  className="PB-range-slider"
                  id="myRange"
                />
              </div>
            </>
          ) : (
            <MultibleItemQty qty={dispatchQTY} setQty={setQty} />
          )}
          <h6 style={{ textAlign: "center" }}>
            Customer Quantity: {Number(product.quantity) || "N/A"}
          </h6>
        </div>
        <div style={{ display: "flex", columnGap: "20px" }}>
          <SubmitBT
            BTname="Cancel"
            style={{ backgroundColor: "gray" }}
            onClick={() => onSubmit(null)}
          />
          <SubmitBT
            BTname="Set Qty"
            onClick={() =>
              onSubmit({ ...product, dispatchQTY, editedQuantity: true })
            }
          />
        </div>
      </div>
    </div>
  );
}

const SingleItemQty = ({ qty = 1, setQty }) => {
  const qtyRef = useRef();

  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <DashLg
        size={"1.5rem"}
        onClick={() => qty > 0 && setQty(fixNumber(qty - 1))}
      />
      <input
        ref={qtyRef}
        onFocus={() => qtyRef.current.select()}
        className="text-center"
        style={{
          border: 0,
          fontSize: "1.5rem",
          fontWeight: 600,
          width: "30%",
        }}
        type="number"
        value={fixNumber(qty)}
        onChange={(e) => setQty(fixNumber(e.target.value))}
      />
      <PlusLg size={"1.5rem"} onClick={() => setQty(fixNumber(qty + 1))} />
    </div>
  );
};

const MultibleItemQty = ({ qty = 0, setQty }) => {
  const [qtys, setQtys] = useState([qty]);
  const lastItemRef = useRef(null);
  const isAdding = useRef(false); // Track whether the operation is "add"

  useEffect(() => {
    setQty(
      qtys.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    );
    if (lastItemRef.current && isAdding.current) {
      lastItemRef.current.scrollIntoView({ behavior: "smooth" });
    }
    isAdding.current = false;
  }, [qtys]);

  const addQty = () => {
    isAdding.current = true; // Set the flag to true before adding
    setQtys([...qtys, 0]);
  };

  const handleValueChange = (index, qty) => {
    setQtys(qtys.map((val, i) => (i === index ? qty : val)));
  };

  return (
    <div>
      <h4>Total Qty: {qty}</h4>
      <div
        style={{ maxHeight: "40vh", overflowY: "auto", scrollbarWidth: "none" }}
      >
        {qtys.map((val, index) => (
          <ListGroup horizontal key={index}>
            <ListGroup.Item
              style={{
                width: "15%",
                textAlign: "center",
                alignContent: "center",
                fontWeight: 600,
                padding: 0,
              }}
            >
              {index + 1}
            </ListGroup.Item>
            <ListGroup.Item style={{ width: "70%" }}>
              <SingleItemQty
                qty={val}
                setQty={(e) => handleValueChange(index, e)}
              />
            </ListGroup.Item>
            <ListGroup.Item
              style={{
                width: "15%",
                textAlign: "center",
                alignContent: "center",
                fontWeight: 600,
                padding: 0,
              }}
              className="text-danger"
              onClick={() =>
                qtys.length > 1 && setQtys(qtys.filter((_, i) => i !== index))
              }
            >
              X
            </ListGroup.Item>
          </ListGroup>
        ))}
        {qtys.length < 99 && (
          <ListGroup className="mb-2" style={{ fontWeight: 600 }}>
            <ListGroup.Item onClick={addQty} ref={lastItemRef}>
              Add+
            </ListGroup.Item>
          </ListGroup>
        )}
      </div>
    </div>
  );
};

const fixNumber = (number) => {
  return parseFloat(Number(number).toFixed(2));
};

const preventBubbling = (event) => {
  event.stopPropagation();
};

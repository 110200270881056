import SubmitBT from "../../components/buttons/SubmitBT";
import ListGroup from "react-bootstrap/ListGroup";
import { useState, useEffect } from "react";
import { useFetch, useUpdate } from "../../db/dbHooks";
import QtyEditor from "../../components/createOder/QtyEditor";
import BatchEditor from "./BatchEditor";
import { usePopup } from "../floating/PopupContext";
import Loader from "../loader/Loader";

const DispatchCutomerMiddle = ({
  selectedCustomers,
  setUpdateView,
  setLock,
}) => {
  const { showPopupSuccess, showPopupError } = usePopup();
  const [addBatchNo, setAddBatchNo] = useState(null);
  const [qtyToEdit, setQtyToEdit] = useState(null);
  const [clickedItem, setClickedItem] = useState(-1);
  const [orderDetails, setOrderDetails] = useState(null);
  const { data, loading, error, refetch } = useFetch(
    `orders/orderwithdetails/${selectedCustomers.orderID}`
  );
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("dispatch");

  useEffect(() => {
    setClickedItem(-1);
  }, [selectedCustomers]);

  useEffect(() => {
    if (data) {
      setOrderDetails(data.orderDetails);
    } else if (error) {
      showPopupError(error);
    }
  }, [data, error]);

  useEffect(() => {
    if (updateResponse) {
      setLock(false);
      refetch();
      setUpdateView(true);
      showPopupSuccess(updateResponse.message);
    } else if (updateError) {
      showPopupError(updateError);
    }
  }, [updateResponse, updateError]);

  const handleSubmit = (e, completeOrder = false) => {
    let itemsToUpdate = [];
    let total = 0;
    let patchNotEntered = 0;

    orderDetails.map((item) => {
      if (!item.batch && !item.outOfStock && completeOrder) {
        patchNotEntered += 1;
      }
      if (
        item.editedBatch === true ||
        item.editedQuantity === true ||
        item.edited ||
        (!item.dispatchQTY && completeOrder)
      ) {
        itemsToUpdate.push({
          dispatchQTY: item.dispatchQTY ? item.dispatchQTY : item.quantity,
          batch: item.batch,
          outOfStock: item.outOfStock,
          orderDetailsID: item.orderDetailsID,
        });
      }
      if (!item.outOfStock) {
        total +=
          item.price * (item.dispatchQTY ? item.dispatchQTY : item.quantity);
      }
    });
    if (
      (itemsToUpdate.length > 0 && !completeOrder) ||
      (completeOrder && patchNotEntered === 0)
    ) {
      updateData("", {
        orderData: {
          total: total,
          orderID: selectedCustomers.orderID,
          status: completeOrder ? "Dispatched" : "Processing",
        },
        updatedOrderItems: itemsToUpdate,
      });
    } else if (patchNotEntered > 0) {
      showPopupError(patchNotEntered + " item(s) missing batch");
    } else {
      showPopupError("No changes were made");
    }
  };

  const handleOutOfStock = (product) => {
    setLock(true);
    setOrderDetails(
      orderDetails.map((item) => {
        if (item.productID !== product.productID) {
          return item;
        } else {
          return {
            ...product,
            outOfStock: item.outOfStock ? false : true,
            edited: true,
          };
        }
      })
    );
    setClickedItem(-1);
  };
  const handlePatchUpdate = (product) => {
    if (product) {
      setLock(true);
      setOrderDetails(
        orderDetails.map((item) => {
          if (item.productID !== product.productID) {
            return item;
          } else {
            return product;
          }
        })
      );
      setAddBatchNo(null);
    } else {
      setAddBatchNo(null);
    }
  };

  const handleQtyUpdate = (product) => {
    if (product) {
      setLock(true);
      setOrderDetails(
        orderDetails.map((item) => {
          if (item.productID !== product.productID) {
            return item;
          } else {
            return product;
          }
        })
      );
      setQtyToEdit(null);
    } else {
      setQtyToEdit(null);
    }
  };
  return (
    <div className="dispatch_middle_container">
      <h3>{selectedCustomers.customerName}</h3>
      {loading ? (
        <Loader color={"black"} />
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h6 style={{ textAlign: "left", width: "100%" }}>
              {"Status: " + (data && data.order.status)}
            </h6>
            <h6 style={{ textAlign: "left", width: "100%" }}>
              {"Customer ID: " + (data && data.order.customerID)}
            </h6>
          </div>

          <div id="cutomer_middle_list">
            <ListGroup horizontal className="list-header sticky">
              <ListGroup.Item>Product</ListGroup.Item>
              <ListGroup.Item>Batch</ListGroup.Item>
              <ListGroup.Item>Quantity</ListGroup.Item>
            </ListGroup>
            {orderDetails &&
              orderDetails.map((item, index) => (
                <>
                  <ListGroup horizontal>
                    <ListGroup.Item
                      style={{
                        backgroundColor: item.outOfStock ? "#d80000" : "",
                      }}
                      onClick={() =>
                        setClickedItem(clickedItem === index ? -1 : index)
                      }
                    >
                      {item.productName}
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={item.editedBatch ? "edited" : ""}
                      onClick={() => setAddBatchNo(item)}
                    >
                      {item.batch}
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={item.editedQuantity ? "edited" : ""}
                      onClick={() => setQtyToEdit(item)}
                    >
                      {item.dispatchQTY
                        ? parseFloat(item.dispatchQTY)
                        : parseFloat(item.quantity)}
                    </ListGroup.Item>
                  </ListGroup>
                  {clickedItem === index && (
                    <ListGroup>
                      <ListGroup.Item
                        style={{
                          width: "100%",
                          textAlign: "center",
                          backgroundColor: item.outOfStock ? "green" : "red",
                          fontWeight: 600,
                        }}
                        onClick={() => handleOutOfStock(item)}
                      >
                        {item.outOfStock
                          ? "Mark Available"
                          : "Mark Out Of Stock"}
                      </ListGroup.Item>
                    </ListGroup>
                  )}
                </>
              ))}
          </div>
          {data?.order.note && (
            <div style={{ width: "100%" }}>
              <p className="mb-0">Customer Note:</p>
              <ListGroup>
                <ListGroup.Item
                  style={{ maxHeight: "63px", overflowY: "auto" }}
                >
                  {data.order.note}
                </ListGroup.Item>
              </ListGroup>
            </div>
          )}
          <div style={{ display: "flex", width: "100%", columnGap: "20px" }}>
            <SubmitBT
              BTname="Save Changes"
              onClick={handleSubmit}
              loading={updateLoading}
            />
            <SubmitBT
              BTname="Complete Order"
              onClick={() => handleSubmit("", true)}
              loading={updateLoading}
              style={{ backgroundColor: "green" }}
            />
          </div>
          {addBatchNo && (
            <BatchEditor
              productData={addBatchNo}
              onSubmit={handlePatchUpdate}
            />
          )}
          {qtyToEdit && (
            <QtyEditor onSubmit={handleQtyUpdate} product={qtyToEdit} />
          )}
        </>
      )}
    </div>
  );
};

export default DispatchCutomerMiddle;

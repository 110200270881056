import { useState, useEffect, memo } from "react";
import "./CreateOrder.css";
import { ArrowLeftShort, X } from "react-bootstrap-icons";
import ListGroup from "react-bootstrap/ListGroup";
export default memo(function ProductPicker({
  showList,
  setShowList,
  productData,
  categoryData,
  selectedProduct,
}) {
  const [category, setCategory] = useState(null);

  useEffect(() => {
    setCategory(null);
  }, [showList]);

  const buttonClick = (product) => {
    let merged = { ...category, ...product, quantity: 1 };
    selectedProduct(merged);
    setShowList(false);
  };
  const preventBubbling = (event) => {
    event.stopPropagation();
  };
  return (
    showList && (
      <div className="fixed-bg" onClick={() => setShowList(false)}>
        <div className="fixed-inner" onClick={preventBubbling}>
          <ProductDisplay
            category={category}
            backClick={() => setCategory(null)}
            exitClick={() => setShowList(false)}
          />
          <div className="product-picker-list">
            {!category ? (
              <CategoryList
                categories={[
                  ...categoryData,
                  { categoryName: "uncategorised", categoryID: null },
                ]}
                selectedItem={setCategory}
              />
            ) : (
              <ProductList
                category={category}
                products={productData.filter(
                  (item) => item.categoryID === category.categoryID
                )}
                selectedItem={buttonClick}
              />
            )}
          </div>
        </div>
      </div>
    )
  );
});

const ProductDisplay = ({
  category = null,
  product = null,
  backClick,
  exitClick,
}) => {
  return (
    <div className="d-flex ">
      {
        <ArrowLeftShort
          size={40}
          style={{ margin: "auto" }}
          hidden={category ? false : true}
          onClick={backClick}
        />
      }
      <div
        style={{
          width: "80%",
          height: "10%",
          flexGrow: "10",
          fontWeight: 600,
          alignContent: "center",
        }}
      >
        {category && !product && category.categoryName}
        {/*         {product && product.productName }
         */}{" "}
      </div>
      {<X size={40} style={{ margin: "auto" }} onClick={exitClick} />}
    </div>
  );
};

const CategoryList = ({ categories = [], selectedItem }) => {
  return (
    <>
      <ListGroup className="list-header sticky">
        <ListGroup.Item className="w-100 "> Category Name </ListGroup.Item>
      </ListGroup>
      {categories?.map((category) => (
        <ListGroup
          horizontal
          key={category.categoryID}
          className="clickable_list_item"
        >
          <ListGroup.Item
            className="w-100 "
            onClick={() => selectedItem(category)}
          >
            {" "}
            {category.categoryName}
          </ListGroup.Item>
        </ListGroup>
      ))}
    </>
  );
};

const ProductList = ({ products = [], selectedItem }) => {
  return (
    <>
      <ListGroup horizontal className="list-header sticky">
        <ListGroup.Item className="w-75 text-start">
          {" "}
          Product Name{" "}
        </ListGroup.Item>
        <ListGroup.Item className="w-25 text-center"> Price </ListGroup.Item>
      </ListGroup>
      {products?.map((product) => (
        <ListGroup
          className="clickable_list_item"
          horizontal
          key={product.productID}
          onClick={() => selectedItem(product)}
        >
          <ListGroup.Item className="w-75 text-start">
            {product.productName}
          </ListGroup.Item>
          <ListGroup.Item className="w-25 text-center">
            {Number(product.price)}{" "}
          </ListGroup.Item>
        </ListGroup>
      ))}
    </>
  );
};

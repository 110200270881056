import { useContext } from "react";
import { useCookies } from "react-cookie";
import { AuthContext } from "../../fun/authContext";

const SageSignin = () => {
  const [cookies, setCookies] = useCookies(["sageToken"]);
  const { user } = useContext(AuthContext);

  return (
    !user.sageLoginState && (
      <a
        href={`${process.env.REACT_APP_API_URL}auth/sageAuth?user_id=${user.userID}`}
        style={{
          position: "absolute",
          left: 0,
          top: "7vh",
          width: "100%",
          backgroundColor: "red",
          color: "black",
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Click Here To Signin To Sage
      </a>
    )
  );
};

export default SageSignin;

// context/PopupContext.js
import React, { createContext, useState, useContext } from "react";
import Popup from "./Popup";

const PopupContext = createContext();

export const usePopup = () => useContext(PopupContext);

export const PopupProvider = ({ children }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const showPopupSuccess = (success = null, error = null) => {
    setError(error);
    setSuccess(success);
    setPopupVisible(true);
  };

  const showPopupError = (error = null) => {
    setError(error);
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupVisible(false);
    setError(null);
    setSuccess(null);
  };

  return (
    <PopupContext.Provider
      value={{ showPopupSuccess, showPopupError, hidePopup }}
    >
      {children}
      {isPopupVisible && (
        <Popup error={error} response={success} setTrigger={hidePopup} />
      )}
    </PopupContext.Provider>
  );
};

// DropdownTab.js
import React, { useState, useRef, useEffect } from "react";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";

const DropdownTab = ({ title, children, updateView = false, open = false }) => {
  const [isOpen, setIsOpen] = useState(open);
  const contentRef = useRef(null); // Reference to measure the content height

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  // Measure the content height dynamically for smooth animation
  useEffect(() => {
    if (isOpen && contentRef.current) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      contentRef.current.style.marginBottom = `20px`;
    } else if (contentRef.current) {
      contentRef.current.style.maxHeight = "0px";
      contentRef.current.style.marginBottom = `0px`;
    }
  }, [isOpen, updateView]);

  return (
    <div
      style={{
        marginBottom: "10px",
        borderBottom: "1px solid #ddd",
        width: "100%",
      }}
    >
      <div
        onClick={toggleOpen}
        style={{
          cursor: "pointer",
          padding: "10px 20px",
          display: "flex",
          borderBottom: "1px solid #ddd",

          justifyContent: "space-between",
        }}
      >
        {<h5>{title}</h5>}
        {isOpen ? <CaretUpFill /> : <CaretDownFill />}
      </div>
      <div
        ref={contentRef}
        style={{
          maxHeight: "0px",
          overflow: "hidden",
          transition: "max-height 0.3s ease-out",
          background: "#ffffff",
          padding: isOpen ? "10px" : "0px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default DropdownTab;

// context/PopupContext.js
import React, { createContext, useState, useContext } from "react";
import LoadingSpinner from "./LoadingSpinner";

const LoadingSpinnerContext = createContext();

export const useLoadingSpinner = () => useContext(LoadingSpinnerContext);

export const LoadingSpinnerProvider = ({ children }) => {
  const [isLoadingSpinnerVisible, setIsLoadingSpinnerVisible] = useState(false);
  const [message, setMessage] = useState({ type: null, message: "" });

  const showLoadingSpinner = () => {
    setIsLoadingSpinnerVisible(true);
  };

  const setLoadingSpinnerMessage = (type, message) => {
    setMessage({ type, message });
  };

  const hideLoadingSpinner = () => {
    setTimeout(() => {
      setIsLoadingSpinnerVisible(false);
      setMessage({ type: null, message: "" });
    }, 3000); // 2 seconds delay
  };

  return (
    <LoadingSpinnerContext.Provider
      value={{
        showLoadingSpinner,
        hideLoadingSpinner,
        setLoadingSpinnerMessage,
      }}
    >
      {children}
      {isLoadingSpinnerVisible && (
        <LoadingSpinner type={message.type} message={message.message} />
      )}
    </LoadingSpinnerContext.Provider>
  );
};

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import "./Cards.css";

function Cards({
  onClick,
  cardHeading,
  date,
  ordersToGet,
  ordersCount,
  noOrders,
}) {
  return (
    <Card className="card_main">
      <Card.Body>
        <Card.Title className="text-center">
          {cardHeading}
          <br /> {date}
        </Card.Title>

        <ListGroup horizontal>
          <ListGroup.Item className="flex-fill text-start remove-border">
            Orders to get:{" "}
          </ListGroup.Item>
          <ListGroup.Item className="flex-fill text-end remove-border text-danger">
            {" "}
            {Number(ordersToGet) -
              Number(ordersCount || 0) -
              Number(noOrders || 0)}
          </ListGroup.Item>
        </ListGroup>

        <ListGroup horizontal>
          <ListGroup.Item className="flex-fill text-start remove-border">
            Available orders:{" "}
          </ListGroup.Item>
          <ListGroup.Item className="flex-fill text-end remove-border text-success">
            {" "}
            {ordersCount}
          </ListGroup.Item>
        </ListGroup>
        <ListGroup horizontal>
          <ListGroup.Item className="flex-fill text-start remove-border">
            No orders:{" "}
          </ListGroup.Item>
          <ListGroup.Item className="flex-fill text-end remove-border text-danger">
            {" "}
            {noOrders}
          </ListGroup.Item>
        </ListGroup>

        <Button variant="primary" className="button-submit" onClick={onClick}>
          See Outstanding
        </Button>
      </Card.Body>
    </Card>
  );
}

export default Cards;

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useEffect } from "react";
import { useFetch, usePost, useUpdate } from "../../db/dbHooks";
import SubmitBT from "../buttons/SubmitBT";
import { usePopup } from "../floating/PopupContext";
import ListGroup from "react-bootstrap/ListGroup";

export default function Route({ routeToEdit = null, triggerUpdate }) {
  const { showPopupSuccess, showPopupError } = usePopup();
  const [validated, setValidated] = useState(false);
  const [route, setRoute] = useState(
    routeToEdit
      ? routeToEdit
      : { routeID: null, routeName: "", days: [], customers: null }
  );
  const [selectedCustomerIDs, setSelectedCustomerIDs] = useState([]);

  var days = [];
  const checkBoxes = [
    { ID: "Sun", Value: 1, Checked: false },
    { ID: "Mon", Value: 2, Checked: false },
    { ID: "Tue", Value: 3, Checked: false },
    { ID: "Wed", Value: 4, Checked: false },
    { ID: "Thu", Value: 5, Checked: false },
    { ID: "Fri", Value: 6, Checked: false },
    { ID: "Sat", Value: 7, Checked: false },
  ];
  const [checkboxList, setCheckboxList] = useState(checkBoxes);

  const { postResponse, postLoading, postError, postData } = usePost("routes");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("routes");
  const { data, loading, error, refetch } = useFetch("customers");

  useEffect(() => {
    if (postResponse) {
      setValidated(false);
      triggerUpdate();
      resetState();
      refetch();
      showPopupSuccess(postResponse?.message);
      //resetState(true);
    } else if (postError) {
      showPopupError(postError);
    }
  }, [routeToEdit, postResponse, postError]);

  //handle update response
  useEffect(() => {
    if (updateResponse && routeToEdit) {
      setValidated(false);
      triggerUpdate();
      showPopupSuccess(updateResponse?.message);
    } else if (updateError && routeToEdit) {
      showPopupError(updateError);
    }
    !routeToEdit && resetState(false);
    routeToEdit && setCheckedBoxes();
  }, [routeToEdit, updateResponse, updateError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    days = getCheckedBoxs();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (days.length > 0) {
        if (routeToEdit) {
          if (
            JSON.stringify(routeToEdit) !==
              JSON.stringify({ ...route, days: days }) ||
            selectedCustomerIDs.length > 0
          ) {
            updateData(0, {
              route: {
                routeID: route.routeID,
                routeName: route.routeName,
                days: days,
                customers: selectedCustomerIDs,
              },
            });
          } else {
            showPopupError("No changes were made.");
          }
        } else {
          postData({
            route: {
              ...route,
              days: getCheckedBoxs(),
              customers: selectedCustomerIDs,
            },
          });
        }
      } else {
        showPopupError("At least one day should be selected");
      }
    }
    setValidated(true);
  };

  const resetState = (updateData) => {
    setRoute({
      routeID: null,
      routeName: "",
      routeDays: "",
    });
    setSelectedCustomerIDs([]);
    setCheckboxList(checkBoxes);
  };

  function getCheckedBoxs() {
    let checked = [];
    checkboxList.map((item) => {
      if (item.Checked) checked.push(item.Value);
    });
    return checked;
  }
  function setCheckedBoxes() {
    let item = null;
    setCheckboxList(
      checkBoxes.map((box) => {
        item = box;
        route.days.map((day) => {
          if (box.Value === day) item = { ...box, Checked: true };
        });
        return item;
      })
    );
  }
  const handleCheck = (e) => {
    setCheckboxList(
      checkboxList.map((box) => {
        if (box.ID === e.target.id) {
          return { ...box, Checked: !box.Checked };
        } else {
          return box;
        }
      })
    );
  };
  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="form"
    >
      <Form.Group as={Col}>
        <Form.Label>Route Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter route name"
          required
          id="routeName"
          value={route.routeName}
          onChange={(e) => setRoute({ ...route, routeName: e.target.value })}
        />
      </Form.Group>
      <Form.Label> Delivery Days</Form.Label>

      <Row>
        <Form.Group
          as={Col}
          className="d-flex flex-wrap justify-content-around"
        >
          {checkboxList.map((item) => (
            <Form.Check
              key={item.Value}
              inline
              label={item.ID}
              type="checkbox"
              id={item.ID}
              value={item.Value}
              checked={item.Checked}
              onChange={handleCheck}
            />
          ))}
        </Form.Group>
      </Row>

      <CustomerList
        customersData={data}
        route={route}
        selectedCustomerIDs={selectedCustomerIDs}
        setSelectedCustomerIDs={setSelectedCustomerIDs}
      />

      <Row style={{ justifyContent: "space-around" }}>
        <SubmitBT
          loading={updateLoading || postLoading}
          BTname={routeToEdit ? "Update Route" : "Add Route"}
        />
      </Row>
    </Form>
  );
}

const CustomerList = ({
  customersData,
  route,
  setSelectedCustomerIDs,
  selectedCustomerIDs,
}) => {
  const [customers, setCustomers] = useState(customersData);

  useEffect(() => {
    if (customersData) {
      if (route.routeID) {
        const routeCustomers = customersData.filter(
          ({ routeID }) => routeID === route.routeID
        );
        const notRouteCustomers = customersData
          .sort((a, b) => b.routeID - a.routeID)
          .filter(({ routeID }) => routeID !== route.routeID);
        setCustomers([...routeCustomers, ...notRouteCustomers]);
      } else {
        setCustomers(
          customersData
            .sort((a, b) => b.routeID - a.routeID)
            .filter(({ routeID }) => routeID === null)
        );
      }
    }
  }, [customersData, route]);

  const handleItemClick = (customer) => {
    setSelectedCustomerIDs((prevIDs) => {
      if (prevIDs.includes(customer)) {
        // Remove the customerID if it's already selected
        return prevIDs.filter((id) => id !== customer);
      } else {
        // Add the customerID if it's not already selected
        return [...prevIDs, customer];
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "stretch",
      }}
    >
      <h6 className="mb-2">Assign customers to route</h6>
      <ListGroup
        style={{
          overflowY: "auto",
          maxHeight: "35vh",
        }}
      >
        {customers?.map((customer) => (
          <ListGroup.Item
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            as="li"
            key={customer.customerID}
            active={selectedCustomerIDs.includes(customer)} // Highlight if in the selectedCustomerIDs array
            onClick={() => handleItemClick(customer)} // Add click handler
          >
            <p className="mb-0"> {customer.customerName} </p>
            {customer.routeID === route.routeID && customer.routeID !== null ? (
              <p
                style={{
                  color: selectedCustomerIDs.includes(customer)
                    ? "white"
                    : "Green",
                  fontWeight: 600,
                  marginBottom: 0,
                }}
              >
                {selectedCustomerIDs.includes(customer)
                  ? "Will be removed"
                  : "Added"}
              </p>
            ) : (
              <p
                style={{
                  color: selectedCustomerIDs.includes(customer)
                    ? "white"
                    : "Green",
                  fontWeight: 600,
                  marginBottom: 0,
                }}
              >
                {customer.routeName && customer.routeName}
              </p>
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

import PageTitle from "../../components/pageTitle/PageTitle";
import { useState, useMemo } from "react";
import { useFetch } from "../../db/dbHooks";
import ListGroup from "react-bootstrap/ListGroup";
import { euDate } from "../../fun/fun";
import SubmitBT from "../../components/buttons/SubmitBT";
import OrderView from "../../components/createOder/OrderView";
import Filter from "../../components/filter/Filter";
import Loader from "../../components/loader/Loader";

export default function OrderHistory() {
  const [route, setRoute] = useState(null);
  const [url, setUrl] = useState({ orderUrl: "", customersUrl: "" });
  const [customerName, setCustomerName] = useState("");
  const [routeName, setRouteName] = useState("");
  const [orderID, setOrderID] = useState(null);

  const {
    data: customers,
    loading: customersLoading,
    /*     error: customersError,
     */
  } = useFetch(url.customersUrl);
  const {
    data: routes,
    loading: routesLoading,
    /*     error: routesError,
     */
  } = useFetch("routes");
  const {
    data: orders,
    loading: ordersLoading,
    /*     error: ordersError,
     */
  } = useFetch(url.orderUrl);

  const handleSelection = (e) => {
    if (!route) {
      setUrl({ ...url, customersUrl: `customers/byRoute/${e.routeID}?` });
      setRoute(e);
      setRouteName(e.routeName);
    }
  };
  return (
    <div className="form-page">
      <PageTitle title={"Order History"} />

      {routesLoading || customersLoading || ordersLoading ? (
        <Loader color={"black"} />
      ) : (
        <>
          <>
            {routes && !route && (
              <div className="manage-list-container">
                <List
                  data={routes}
                  keys={["routeID", "routeName"]}
                  listHeader={"Routes"}
                  selectedItem={handleSelection}
                />
              </div>
            )}

            {customers && route && !customerName && (
              <div className="manage-list-container">
                <List
                  data={customers}
                  keys={["customerID", "customerName"]}
                  listHeader={routeName + " Customers"}
                  selectedItem={(e) => {
                    setUrl({
                      ...url,
                      orderUrl: `orders/orderByCustomer/${e.customerID}?`,
                    });
                    setCustomerName(e.customerName);
                  }}
                />
              </div>
            )}

            {orders && customerName && route && (
              <>
                <OrderHistoryList
                  customerName={customerName}
                  orderData={orders}
                  setOrderID={setOrderID}
                />
                <SubmitBT
                  BTname="Reset"
                  style={{ maxWidth: "100px" }}
                  onClick={() => {
                    setCustomerName("");
                    setRoute(null);
                    setUrl({ orderUrl: "", customersUrl: "" });
                  }}
                />
              </>
            )}
          </>

          {orderID && (
            <OrderView orderID={orderID} setTrigger={() => setOrderID(false)} />
          )}
        </>
      )}
    </div>
  );
}

const OrderHistoryList = ({ customerName, orderData, setOrderID }) => {
  return (
    <div style={{ maxWidth: "600px", width: "100%", fontSize: "12px" }}>
      <h4 className="text-center">{customerName}</h4>
      {orderData.length > 0 ? (
        <>
          {" "}
          <ListGroup horizontal className="list-header">
            <ListGroup.Item className="w-25">Order Date</ListGroup.Item>
            <ListGroup.Item className="w-25">Delivery Date</ListGroup.Item>
            <ListGroup.Item className="w-25">Total</ListGroup.Item>
            <ListGroup.Item className="w-25">Status</ListGroup.Item>
          </ListGroup>
          {orderData &&
            orderData.map((order) => (
              <ListGroup
                className="clickable_list_item"
                key={order.orderID}
                horizontal
                style={{ margin: "4px 0" }}
                onClick={() => setOrderID(order.orderID)}
              >
                <ListGroup.Item className="w-25">
                  {euDate(order.orderDate)}
                </ListGroup.Item>
                <ListGroup.Item className="w-25">
                  {euDate(order.deliveryDate)}
                </ListGroup.Item>
                <ListGroup.Item className="w-25">
                  {Number(order.total)}
                </ListGroup.Item>
                <ListGroup.Item className="w-25">{order.status}</ListGroup.Item>
              </ListGroup>
            ))}
        </>
      ) : (
        <h6 className="text-center">No Order History</h6>
      )}
    </div>
  );
};

export const List = ({ data, selectedItem, listHeader, keys }) => {
  const [search, setSearch] = useState("");

  // Memoize filtered data based on search input
  const filteredData = useMemo(() => {
    const searchRegex = new RegExp(search, "i");
    return data?.filter(
      (item) => search === "" || searchRegex.test(item[keys[1]])
    );
  }, [search, data, keys]);

  // Early return if no data is provided
  if (!data) return null;

  return (
    <div className="list-container">
      {data.length > 0 ? (
        <>
          <div className="sticky">
            <Filter setSearch={setSearch} />
            <ListGroup horizontal className="list-header">
              <ListGroup.Item className="item1 w-100">
                {listHeader}
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="list-body">
            {filteredData.length > 0 ? (
              filteredData.map((item) => {
                const itemId = item[keys[0]];
                const itemName = item[keys[1]];
                return (
                  <div key={itemId}>
                    <ListGroup
                      className="clickable_list_item"
                      horizontal
                      onClick={() => selectedItem(item)}
                    >
                      <ListGroup.Item className="item1 w-100">
                        {itemName}
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                );
              })
            ) : (
              <p className="text-center mt-4">No matches found</p>
            )}
          </div>
        </>
      ) : (
        <p className="text-center mt-4">No customers added</p>
      )}
    </div>
  );
};

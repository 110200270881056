import { useFetch } from "../../db/dbHooks";
import { euDate } from "../../fun/fun";
import Cards from "../card/Cards";
import PageTitle from "../pageTitle/PageTitle";
import "./OutstandingOrders.css";
import { useState } from "react";
import Loader from "../loader/Loader";
import OutstandingDetailed from "./OutstandingDetailed.js";
export default function OutstandingOrders() {
  const [selectedRoute, setSelectedRoute] = useState(null);
  const { data, loading, error, refetch } = useFetch("routes/routesOverview");
  return (
    <div style={{ textAlign: "center" }}>
      <PageTitle title={"Routes Overview"} />
      {selectedRoute && (
        <OutstandingDetailed
          route={selectedRoute}
          setRoute={(e) => setSelectedRoute(e)}
          update={refetch}
        />
      )}
      {loading ? (
        <Loader color={"black"} />
      ) : (
        <div className="cardsContainer">
          {data?.length > 0 ? (
            data.map((route) => (
              <Cards
                key={route.routeName + " " + route.runDate}
                route={route}
                cardHeading={route.routeName}
                date={euDate(route.runDate)}
                ordersToGet={route.customerCount}
                ordersCount={route.availableOrdersCount}
                noOrders={route.noOrders}
                onClick={() => setSelectedRoute(route)}
              />
            ))
          ) : (
            <p>No Routes added</p>
          )}
        </div>
      )}
    </div>
  );
}

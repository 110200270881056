import FormTab from "../../components/forms/FormTab";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useState, useEffect } from "react";
import Product from "../../components/forms/Product";
import { useFetch, useDelete } from "../../db/dbHooks";
import ProductList from "../../components/list/ProductList";
import { useConfirmation } from "../../components/floating/ConfirmationPopupContext.js";
import { usePopup } from "../../components/floating/PopupContext.js";
import Loader from "../../components/loader/Loader.js";

export default function ManageProducts() {
  const { showConfirmation } = useConfirmation();
  const { showPopupSuccess, showPopupError } = usePopup();
  const [selectedTab, setSelectedTab] = useState("New Product");
  const [prodcutToEdit, setProductToEdit] = useState(null);
  const { data, loading, error, refetch } = useFetch("products");
  const { data: categoryData, error: categoryError } = useFetch("categories");
  const { deleteResponse, deleteLoading, deleteError, deleteData } =
    useDelete("products");
  const [sageURL, setSageURL] = useState({ url: "", product: null });

  const {
    data: sageCustomer,
    loading: sageLoading,
    error: sageError,
  } = useFetch(sageURL.url, true);

  useEffect(() => {
    setProductToEdit(null);
    setSageURL({ url: "", customer: null });
  }, [selectedTab]);

  useEffect(() => {
    if (deleteResponse) {
      showPopupSuccess(deleteResponse.message);
      refetch();
    } else if (deleteError) {
      showPopupError(deleteError);
    }
  }, [deleteResponse, deleteError]);

  useEffect(() => {
    if (sageError) {
      showPopupError(sageError);
    }
  }, [sageError]);

  const update = () => {
    refetch();
    setProductToEdit(null);
  };

  useEffect(() => {
    if (sageCustomer) {
      setProductToEdit({
        productID: sageURL.product.productID,
        sageID: sageCustomer.id,
        productName: sageCustomer.displayed_as,
        unit: sageURL.product.unit,
        price: sageCustomer.sales_prices[0].price,
        categoryID: sageURL.product.categoryID,
        costPrice: sageCustomer.cost_price,
        sageRequest: true,
      });
      setSageURL({ url: "", customer: null });
    }
  }, [sageCustomer]);

  const handleProductSelection = (productData) => {
    if (productData.sageID) {
      //setSelectedCustomer(customer)
      setSageURL({
        url: `products/sageProduct/${productData.sageID}`,
        product: productData,
      });
    } else {
      setProductToEdit(productData);
    }
  };

  return (
    <div className="form-page">
      <PageTitle title={"Manage Products"} />
      <FormTab
        tabs={["New Product", "Edit Product"]}
        selected={setSelectedTab}
        defaults={0}
      />

      {selectedTab === "New Product" ? (
        <Product
          category={categoryData}
          reFetch={update}
          productToEdit={null}
        />
      ) : selectedTab === "Edit Product" && prodcutToEdit === null ? (
        sageLoading || deleteLoading ? (
          <Loader color={"black"} />
        ) : (
          <div className="manage-list-container">
            <ProductList
              productData={data}
              setProductToEdit={handleProductSelection}
              setProductToDelete={(product) =>
                showConfirmation(
                  "Are you sure you want to delete " + product.productName,
                  () =>
                    deleteData(
                      product.sageID ? product.sageID : product.productID,
                      {
                        sageRequest: product.sageID ? true : false,
                        product,
                      },
                      product.sageID && true
                    ),
                  "Delete"
                )
              }
            />
          </div>
        )
      ) : (
        prodcutToEdit && (
          <Product
            category={categoryData}
            productToEdit={prodcutToEdit}
            reFetch={update}
          />
        )
      )}
    </div>
  );
}

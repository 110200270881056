import ListGroup from "react-bootstrap/ListGroup";
import { useState } from "react";

export default function FormTab({ tabs, selected, defaults }) {
  const [selectedTab, setSelectedTab] = useState(tabs[defaults]);

  return (
    <ListGroup horizontal style={{ padding: "10px" }}>
      {tabs.map((value) => (
        <ListGroup.Item
          key={value}
          style={{ textAlign: "center" }}
          className={selectedTab === value ? "form-tabs-selected" : "form-tabs"}
          onClick={() => {
            setSelectedTab(value);
            selected(value);
          }}
        >
          {value}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

import "./CreateOrder.css";
import ProductPicker from "./ProductPicker";
import { useState, useEffect, useCallback, memo } from "react";
import OrderList from "./OrderList";
import { euDate, nextDeliveryDates, sqlDate } from "../../fun/fun";
import { useFetch } from "../../db/dbHooks.js";
import SubmitBT from "../buttons/SubmitBT.js";
import DropDown from "../dropdown/DropDown.js";
import ListGroup from "react-bootstrap/ListGroup";
import SelectCustomer from "./SelectCustomer.js";
import { usePopup } from "../floating/PopupContext.js";
import ProductList from "../list/ProductList.js";

export default function CreateOrder({
  existingOrderHeader,
  orderItem,
  products,
  categories,
  onSubmit,
  loading,
  resetStates,
}) {
  /*   const savedUnpostedOrder = JSON.parse(
    localStorage.getItem("NF_DB_UNPOSTED_ORDER")
  ); */
  const { showPopupError } = usePopup();
  const [previousProductsListURL, setPreviousProductsListURL] = useState("");
  const { data: previousProducts, error: previousProductsError } = useFetch(
    previousProductsListURL
  );
  const [previousProductsList, setPreviousProductsList] =
    useState(previousProducts);
  /*   const [changes, setChanges] = useState(true);
   */
  const orderHeaderDefault = {
    orderID: null,
    customerID: null,
    customerName: null,
    routeName: null,
    deliveryDate: null,
    routeID: null,
    days: null,
    note: null,
    total: 0,
  };
  const [orderHeader, setOrderHeader] = useState(
    existingOrderHeader || orderHeaderDefault
  );
  const [trigger, setTrigger] = useState(false);
  const [orderItems, setOrderItems] = useState(orderItem || []);
  const [itemsToDelete, setItemsToDelete] = useState([]);

  useEffect(() => {
    if (existingOrderHeader.customerID) {
      setPreviousProductsListURL(
        `orders/PreviousProducts/${existingOrderHeader.customerID}`
      );
    }
  }, [existingOrderHeader]);

  /*   useEffect(() => {
    setOrderItems([]);
    setPreviousProductsList(null);
  }, [reset]); */

  /*   //save unposted order or faild order
  useEffect(() => {
    if (!existingOrderHeader) {
      localStorage.setItem(
        "NF_DB_UNPOSTED_ORDER",
        JSON.stringify({
          orderHeader,
          orderItems,
        })
      );
    }
  }, [orderHeader, orderItems]);
  useEffect(() => {
    console.log("running");
    if (savedUnpostedOrder) {
      setOrderHeader(savedUnpostedOrder.orderHeader);
      setOrderItems(savedUnpostedOrder.orderItems);
    }
  }, []); */
  //calculating order total
  useEffect(() => {
    if (resetStates) {
      setOrderHeader(orderHeaderDefault);
      setOrderItems([]);
      setPreviousProductsListURL("");
      setPreviousProductsList(null);
    }
  }, [resetStates]);

  useEffect(() => {
    let total = 0;
    orderItems.map(({ price, quantity }) => {
      total = total + Number(price) * Number(quantity);
    });
    total = parseFloat(total.toFixed(2));
    setOrderHeader({
      ...orderHeader,
      total,
    });
  }, [orderItems]);

  useEffect(() => {
    if (previousProducts) {
      setPreviousProductsList(previousProducts);
    } else if (previousProductsError) {
      setPreviousProductsList(null);
    }
  }, [previousProducts, previousProductsError]);

  const AddToOrder = useCallback(
    (product, isPreviousProduct = false) => {
      setOrderItems((prevOrderItems) => {
        // Check if product already exists
        if (prevOrderItems.some((i) => product.productID === i.productID)) {
          showPopupError(product.productName + " is already added");
          return prevOrderItems;
        }

        // Add the product
        const updatedProduct = isPreviousProduct
          ? products.find((item) => item.productID === product.productID) ||
            product
          : product;

        return [
          ...prevOrderItems,
          { ...updatedProduct, quantity: product.quantity || 1 },
        ];
      });

      // Remove from previousProductsList if applicable
      setPreviousProductsList((prev) =>
        prev?.filter((e) => e.productID !== product.productID)
      );
    },
    [products, showPopupError]
  );

  //Removing item from the list
  const removeFromOrder = (product) => {
    if (product.orderDetailsID) {
      setItemsToDelete([...itemsToDelete, product.orderDetailsID]);
    }
    setOrderItems(orderItems.filter((i) => product.productID !== i.productID));
  };

  /*   const handleBeforeUnload = (event) => {
    if (changes) {
      event.preventDefault();
      alert("Data will be lost. Do you want to contunie?");
    }
  }; */

  /*   useEffect(() => {
    // Attach the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [changes]); */

  const onOrderHeaderUpdate = useCallback(
    (data) => {
      if (data.customerID) {
        setPreviousProductsListURL(
          `orders/PreviousProducts/${data.customerID}`
        );
      }
      setOrderHeader({ ...orderHeader, ...data });
    },
    [orderHeader, setOrderHeader, setPreviousProductsListURL]
  );

  const showProductList = () => {
    setTrigger(true);
  };
  const handleSubmit = () => {
    if (!orderHeader.customerID) {
      showPopupError("Select a customer");
    } else if (!orderHeader.deliveryDate) {
      showPopupError("Please select a delivery date");
    } else if (orderItems.length === 0) {
      showPopupError("No products added");
    } else {
      let order = {
        ...orderHeader,
        orderDate: sqlDate(),
        deliveryDate: sqlDate(orderHeader.deliveryDate),
        status:
          orderHeader.orderID && orderHeader.status !== "No Order"
            ? "Updated Order"
            : "New Order",
      };
      const data = { order, orderDetails: orderItems, itemsToDelete };
      onSubmit(data);
    }
  };

  //handle price and quantity change after addition
  const handleItemChange = (product) => {
    const updatedItems = orderItems.map((orderItem) =>
      orderItem.productID === product.productID ? product : orderItem
    );
    setOrderItems(updatedItems);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <div className="disktop_product_list">
          <ProductList
            productData={products}
            disableListMenu={true}
            setProductToEdit={(product) => AddToOrder(product, false)}
            disableCenters={true}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <ProductPicker
            showList={trigger}
            setShowList={setTrigger}
            productData={products}
            categoryData={categories}
            selectedProduct={(e) => AddToOrder(e, false)}
          />

          <div className="order-view">
            <OrderHeader
              orderHeaderData={orderHeader}
              updateOrderHeader={onOrderHeaderUpdate}
            />
            <OrderList
              items={orderItems}
              itemToRemove={removeFromOrder}
              showProductList={showProductList}
              previousProducts={previousProductsList}
              productToAdd={(e) => AddToOrder(e, true)}
              handleItemChange={handleItemChange}
            />
            <OrderTotal orderHeader={orderHeader} />
          </div>
        </div>
      </div>

      <div className="create_order_bt">
        <SubmitBT
          BTname={
            orderHeader?.orderID != null && orderHeader.status !== "No Order"
              ? "Update Order"
              : "Create Order"
          }
          loading={loading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

const OrderHeader = memo(function OrderHeader({
  orderHeaderData,
  updateOrderHeader,
}) {
  const [showCustomersList, setShowCustomersList] = useState(false);
  // check if days is array if not split it into an array
  const days = Array.isArray(orderHeaderData.days)
    ? orderHeaderData.days
    : orderHeaderData.days?.split(",");
  const dates = orderHeaderData.days
    ? nextDeliveryDates(days).map((day) => {
        return { label: euDate(day), value: sqlDate(day) };
      })
    : [];
  /*   dates.push(
    { label: "Custom Date", value: "Custom Date" },
    { label: "Collection", value: "Collection" }
  ); */
  const onCustomerSelect = (customer) => {
    setShowCustomersList(false);
    updateOrderHeader({ ...customer, deliveryDate: null, note: null });
  };
  return (
    <>
      <div className="orderDetails" style={{ width: "100%" }}>
        <ListGroup horizontal>
          <ListGroup.Item
            className="order_header_item"
            onClick={() => setShowCustomersList(!showCustomersList)}
            style={{ color: orderHeaderData.customerName ? "" : "red" }}
          >
            {orderHeaderData?.customerName || "Click to select a customer"}
          </ListGroup.Item>
          <ListGroup.Item className="order_header_item">
            {" "}
            {orderHeaderData?.routeName || "N/A"}
          </ListGroup.Item>
        </ListGroup>

        <ListGroup horizontal>
          <ListGroup.Item className="order_header_item">
            <input
              type="text"
              onChange={(e) => updateOrderHeader({ note: e.target.value })}
              placeholder={"Order Note"}
              value={orderHeaderData.note || ""}
            />
          </ListGroup.Item>
          <ListGroup.Item className="order_header_item">
            <DropDown
              options={dates}
              onSelect={(e) =>
                e.label === "Custom Date"
                  ? alert("show Date")
                  : updateOrderHeader({ deliveryDate: e.value })
              }
              placeholder={
                euDate(orderHeaderData.deliveryDate) || "Select Delivery"
              }
              selectedDate={orderHeaderData.deliveryDate}
            />
          </ListGroup.Item>
        </ListGroup>
      </div>
      {showCustomersList && (
        <SelectCustomer
          setShow={setShowCustomersList}
          setSelectedCustomer={onCustomerSelect}
        />
      )}
    </>
  );
});

const OrderTotal = ({ orderHeader }) => {
  return (
    <>
      <ListGroup horizontal className="list-header">
        <ListGroup.Item className="w-50">Total</ListGroup.Item>
        <ListGroup.Item className="item2-3 w-50">
          {Number(orderHeader.total)} €
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

import FolatingBT from "../../components/buttons/FloatingBT";
import DispatchOverview from "../../components/dispatch/DispatchOverview";
import OutstandingOrders from "../../components/outstandingOrders/OutstandingOders";

export default function Drivers() {
  return (
    <div
      style={{
        height: "93vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p style={{ textAlign: "center", fontWeight: 600 }}>Not Available</p>
    </div>
  );
}
